import { configureStore } from '@reduxjs/toolkit'
import historyReducer from '../features/HistorySlice'
import authReducer from '../features/authSlice'

export default configureStore({
    reducer: {
        history: historyReducer,
        auth: authReducer,
    }
})

// I am not perfectly good with the redux-toolkit but can understand code and write basic functions
// advanced features I still need to learn.