import React from 'react'
import { GiMagicLamp } from 'react-icons/gi';
import appLogoWithName from '../Assets/appLogo_wo_icon.png'

function DummyScreenMagicLamp() {
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.8', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className={`flex justify-between gap-5 dark:bg-[#444654] bg-[#C8C8C8] `} style={{ paddingTop: 10, paddingBottom: 20, maxWidth: 800, width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: "row", marginRight: 10 }} className="text-xl dark:text-[#fff] flex gap-5">

                    <button
                        className="dark:text-white text-black text-xl flex flex-col justify-center items-center"
                        title="Magic lamp"
                    >
                    </button>
                </div>
                <img className="h-[2rem] appstorm-logo" src={appLogoWithName} alt="appstorm-logo" />
                <div style={{ display: 'flex', flexDirection: "row", marginRight: 10 }} className="text-xl dark:text-[#fff] flex gap-5">

                    <button
                        className="dark:text-white text-black text-xl flex flex-col justify-center items-center magic-button"
                        title="Magic lamp"
                    >
                        <GiMagicLamp></GiMagicLamp>
                    </button>
                </div>
            </div>
        </div>

    )
}

export default DummyScreenMagicLamp