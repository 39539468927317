import React, { useEffect, useState } from 'react'
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { setUserAndId } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../Conn/session";

function Authorize() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoaing] = useState(true);

    useEffect(() => {
        var params = window.location.search.split('&');
        var iscode = params.find(val => val.indexOf("code=") !== -1);
        if (iscode) {
            fetchToken((val) => {
                if (val.status === 'success') {
                    var code = iscode.split("=")[1];
                    var token = localStorage.getItem("session-token");
                    if (token && code) {
                        var myHeaders = new Headers();
                        myHeaders.append("Accept", "application/json");
                        myHeaders.append("TOKEN", token);
                        var requestOptions = {
                            method: "GET",
                            headers: myHeaders,
                            redirect: "follow",
                            credentials: "include",
                        };
                        fetch("/api/v1/activate?code=" + code, requestOptions)
                            .then((response) => {
                                return response.text();
                            })
                            .then((result) => {
                                // console.log(result);
                                try {
                                    var val = JSON.parse(result);
                                    if (val.data && val.data.user) {
                                        localStorage.setItem("session-token", val.data.token);
                                        localStorage.setItem("appstorm-user", val.data.user.id);
                                        dispatch(
                                            setUserAndId({
                                                email: val.data.user.email,
                                                id: val.data.user.id,
                                            })
                                        );
                                        setIsLoaing(false);
                                        navigate("/");
                                    } else {
                                        alert(val.message);
                                        setIsLoaing(false);
                                        navigate("/");
                                    }
                                }
                                catch (e) {
                                    // console.log("error", e);
                                    alert("Link expired. Try again")
                                    setIsLoaing(false);
                                    navigate("/");
                                }

                            })
                            .catch((error) => console.log("error", error));
                    }
                }
                else {
                    alert('Error in creating session');
                    setIsLoaing(false);
                    navigate("/");
                }
            })


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="w-full h-screen flex justify-center items-center bg-[#f6f6f6]">
            {isLoading && <TailSpin
                height="40"
                width="40"
                color="#ffffff"
                ariaLabel="tail-spin-loading"
                radius="0.1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            }
        </div>
    )
}

export default Authorize