import { createSlice } from "@reduxjs/toolkit";
// History tracking reducer for global history management.

export const historySlice = createSlice({
    name: 'historyFeature',
    initialState: {
        historyData: [],
        currChat: null,
        toogleSidebar: false,
        darkModeState: localStorage.getItem('darkMode') ? localStorage.getItem('darkMode') : 'dark',
        useGPT4: false,
        // useGPT4: localStorage.getItem('ai_gpt4') ? localStorage.getItem('ai_gpt4') === 'true' ? true : false : false,
        fullScreenMode: false,
        currChatFeedValue: [],
        //new valuesssss
        emailNotification: false,
        appList: [],
        sessionData: null,
        chatConv: [],
        tempList: [],
        refresh: true,
        lastLoadedApp: null,
        currentLoadedApp: null,
        activeApps: [],
        numActive: 5,
        loapApp: null

    },
    reducers: {

        setAppList: (state, action) => {
            if (action.payload.appData) {
                state.appList = [...action.payload.appData.user_app];
                state.tempList = [...action.payload.appData.user_app];
                state.sessionData = { ...action.payload.appData };

            }
            else {
                state.appList = [];
                state.tempList = [];
                state.sessionData = { conversations: [] };
            }
            state.refresh = !state.refresh;
        },

        sortappList: (state, action) => {
            var aList = [];
            switch (action.payload.value) {
                case 1:
                    state.appList.map(item => {

                        item.visible = true;
                        aList.push(item);

                    });
                    state.tempList = [...aList];
                    break;

                case 2:

                    state.appList.map(item => {
                        if (item.app_metadata) {
                            if (item.app_metadata.shared === true) {
                                item.visible = true;
                            }
                            else {
                                item.visible = false;

                            }
                        }
                        aList.push(item);

                    });
                    state.tempList = [...aList];
                    break;

                case 3:
                    state.appList.map(item => {
                        if (item.app_metadata) {
                            if (item.app_metadata.bookmarked === true) {
                                item.visible = true;
                            }
                            else {
                                item.visible = false;

                            }
                        }
                        aList.push(item);
                    });
                    state.tempList = [...aList];
                    break;

            }
            state.refresh = !state.refresh;
        },


        //new functionsssss
        searchList: (state, action) => {
            if (action.payload.value) {
                var searchTextL = action.payload.value.toLowerCase();
                var aList = [];
                state.tempList.map(item => {
                    if (item.app_metadata) {
                        if (item.app_name.toLowerCase().includes(searchTextL) || item.prompt.toLowerCase().includes(searchTextL)) {
                            item.visible = true;
                        }
                        else {
                            item.visible = false;

                        }
                    }
                    aList.push(item);

                });
                state.tempList = [...aList];
            }
            else {
                state.tempList = [...state.appList];
            }
            state.refresh = !state.refresh;

        },

        //new functionsssss
        // setHistory: (state, action) => {
        //     state.historyData = [...action.payload.loadedHistory]
        //     // console.log(state.historyData);
        // },

        clearHistory: (state) => {
            state.historyData = []
            // state.historyData.push({
            //     name: 'newChat_1',
            //     chatFeed: []
            // })
            state.currChat = null;
            // console.log('History cleared.') // testing purpose
        },

        deleteSpecificHistory: (state, action) => {
            state.historyData = state.historyData.filter(ele => ele.id !== action.payload.id);
            if (state.currChat.id === action.payload.id) {
                state.currChat = null;
            } // delete function of specific history with specific id.
        },

        // setCurrentChat: (state, action) => {

        //     if (action.payload) {
        //         state.historyData.forEach(curr => {
        //             if (curr.id === action.payload.id) {
        //                 state.currChat = curr;
        //             }
        //         })
        //     }
        //     else {
        //         state.currChat = action.payload;
        //         state.currChatFeedValue = [];

        //     }
        // },

        // addNewChat: (state, action) => {
        //     state.historyData = [...state.historyData, action.payload.newChat];
        //     state.currChat = action.payload.newChat;
        // },

        // changeCurrChatName: (state, action) => {
        //     state.historyData.forEach(curr => {
        //         if (curr.name === action.payload.name) {
        //             curr.name = action.payload.newName
        //             // console.log('curr chat is ' + state.currChat.name)
        //         }
        //     })
        // },

        changeCurrChatFeed: (state, action) => {
            state.historyData.forEach(curr => {
                if (curr.id === action.payload.id) {
                    curr.conversations = action.payload.conversation;
                    state.currChatFeedValue = [...curr.conversations];
                    // console.log(curr.chatFeed)
                }
            })
        },

        setToogle: (state, action) => {
            state.toogleSidebar = action.payload.toogle
            // console.log(state.toogleSidebar) // testing purpose.
        },

        setDarkModeState: (state) => {
            if (state.darkModeState === 'dark') {
                state.darkModeState = 'light'
                localStorage.setItem('darkMode', 'light')
            } else {
                state.darkModeState = 'dark'
                localStorage.setItem('darkMode', 'dark')
            }
            // console.log(state.darkModeState)
        },
        setEmailNotification: (state, action) => {

            state.emailNotification = action.payload.value;
            localStorage.setItem('emailNotification', action.payload.value);
            // console.log(state.darkModeState)
        },
        setGPT4State: (state) => {
            if (state.useGPT4 === true) {
                state.useGPT4 = false
                localStorage.setItem('ai_gpt4', "false")
            } else {
                state.useGPT4 = true
                localStorage.setItem('ai_gpt4', "true")
            }
            // console.log(state.darkModeState)
        },

        setFullScreenMode: (state, action) => {
            state.fullScreenMode = action.payload.mode
            // console.log(state.fullScreenMode)
        },
        changeChatName: (state, action) => {
            state.historyData.forEach(curr => {
                if (curr.id === action.payload.old) {
                    curr.chat_session_metadata.session_name = action.payload.new;
                    return;
                    // console.log(curr.chatFeed)
                }
            })
        },

        changeReaction: (state, action) => {
            var user_app = [...state.appList];
            user_app.every(function (elem, index) {
                if (elem.id === action.payload.id) {
                    if (user_app[index].reactions) {
                        user_app[index].reactions = [action.payload.reaction];
                        return false;
                    }
                }
                return true;
            });
            state.appList = [...user_app];
            state.tempList = [...user_app];
            // state.refresh = !state.refresh;
        },
        changeActionB: (state, action) => {
            var user_app = [...state.appList];
            user_app.every(function (elem, index) {
                if (elem.id === action.payload.id) {
                    if (user_app[index].app_metadata) {
                        user_app[index].app_metadata.bookmarked = action.payload.action.bookmarked;
                        return false;
                    }
                }
                return true;
            });
            state.appList = [...user_app];
            state.tempList = [...user_app];
            // state.refresh = !state.refresh;
        },

        changeActionS: (state, action) => {
            var user_app = [...state.appList];
            user_app.every(function (elem, index) {
                if (elem.id === action.payload.id) {
                    if (user_app[index].app_metadata) {
                        user_app[index].app_metadata.shared = true;
                        return false;
                    }
                }
                return true;
            });
            state.appList = [...user_app];
            state.tempList = [...user_app];
            // state.refresh = !state.refresh;
        },

        addCurrApp: (state, action) => {

            if (action.payload.id) {
                state.sessionData = { id: action.payload.id, conversations: [] };
            }
            else {
                if (action.payload.new) {
                    if (action.payload.app) {
                        // if (state.appList[state.appList.length - 1].prompt === action.payload.app.prompt) {
                        //     state.appList.pop();
                        // }
                        state.appList = [...state.appList, action.payload.app];
                        state.tempList = [...state.appList];
                    }
                }
                else if (action.payload.ideas) {
                    var temp = [...state.appList];

                    if (temp.length > 0 && temp[temp.length - 1].ideas) {
                        temp[temp.length - 1] = action.payload.ideas;
                        state.appList = [...temp];
                    }
                    else {
                        state.appList = [...state.appList, action.payload.ideas];
                    }
                    state.tempList = [...state.appList];
                }
                else {
                    if (action.payload.app) {
                        var temp = [...state.appList];
                        var app = action.payload.app;
                        // app.shouldLoad = true;
                        if (app.id) {
                            delete temp[temp.length - 1].error;
                        }
                        temp[temp.length - 1] = { ...temp[temp.length - 1], ...app }
                        state.appList = [...temp];
                        state.tempList = [...state.appList];
                        // if (app.id) {
                        //     if (state.activeApps.length === state.numActive) {
                        //         let lastApp = state.activeApps.shift();
                        //         state.lastLoadedApp = lastApp;
                        //     }
                        //     state.currentLoadedApp = app.id;
                        //     state.activeApps.push(app.id);
                        // }
                    }
                }

                // state.sessionData = { ...state.sessionData, conversations: [...state.sessionData.conversations, action.payload] };
            }


            state.refresh = !state.refresh;
            // console.log(state.appList);
            // console.log(state.sessionData);
        },
        removeApp: (state, action) => {
            // var temp = state.appList.filter(val => val.id !== action.payload.id);
            var aList = [];
            state.appList.map(item => {
                if (item.id === action.payload.id) {
                    item.deleted = true;
                }

                aList.push(item);

            });

            let appL = aList.filter(val => val.deleted !== true);
            if (appL.length > 0) {
                state.tempList = [...aList];
                state.appList = [...aList];
            }
            else {
                state.tempList = [];
                state.appList = [];
            }


        },
        searchApp: (state, action) => {
            var searchText = action.payload.searchValue.toLowerCase();
            if (searchText) {
                var temp = state.tempList.filter(val => val.app_name.toLowerCase().includes(searchText) || val.prompt.toLowerCase().includes(searchText));
                state.tempList = [...temp];
                state.refresh = !state.refresh;

            }

        },
        resetAll: (state, action) => {

            state.darkModeState = 'dark';

            state.appList = [];
            state.sessionData = null;
            state.tempList = [];

        },
        setlastLoadedApp: (state, action) => {
            if (state.currentLoadedApp) {

                if (state.activeApps.length === state.numActive) {
                    let lastApp = state.activeApps.shift();
                    state.lastLoadedApp = lastApp;
                }
            }
            state.currentLoadedApp = action.payload.id;
            state.activeApps.push(action.payload.id);
        },
        stopApps: (state, action) => {
            var aList = [];
            state.appList.map(item => {
                if (action.payload.ids.indexOf(item.id) !== -1) {
                    item.app_metadata.app_state = false;
                }

                aList.push(item);

            });
            state.tempList = [...aList];
            // console.log(temp)
            state.appList = [...aList];
            // state.refresh = !state.refresh;
        },
        setLoadAppUrl: (state, action) => {
            state.loapApp = action.payload;
        },

    }
})


export const { resetAll, stopApps, searchList, setlastLoadedApp, currentLoadedApp, lastLoadedApp, changeReaction, changeActionS, removeApp, searchApp, changeActionB, setAppList, sortappList, changeChatName, clearHistory, addCurrApp, setToogle, setDarkModeState, setEmailNotification, changeCurrChatFeed, setFullScreenMode, deleteSpecificHistory, currChatFeedValue, setLoadAppUrl, setGPT4State } = historySlice.actions
export default historySlice.reducer



// I have no Idea about  What is redux Thunk