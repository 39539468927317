import './App.css';
import { Route, Routes } from 'react-router-dom'
import HomePage from './Components/HomePage';
import Collab from './Components/Collab';
import Gallery from './Components/Gallery';
import RegisterAndLogin from './pages/RegisterAndLogin';
import Register from './pages/Register';
import Login from './pages/Login';
import { Provider } from 'react-redux';
import Store from './store/store';
import ProtectedRoutes from './Components/ProtectedRoutes';
import ShareUrl from './pages/ShareUrl';
import Callback from './pages/Callback';
import Authorize from './pages/Authorize';
import ResetPass from './pages/ResetPass';
import Mobile from './pages/Mobile';
import RegisterMobile from './pages/RegisterMobile';
import Password from './pages/Password';
import React, { useEffect } from 'react';


function App() {

  // useEffect(() => {
  //   const gradioVersion = process.env.REACT_APP_GRADIO_VERSION; // Assuming your API response has a 'version' field

  //   // Replace the {VERSION} placeholder with the fetched Gradio version
  //   const gradioScript = document.createElement('script');
  //   gradioScript.type = 'module';
  //   gradioScript.src = `https://gradio.s3-us-west-2.amazonaws.com/${gradioVersion}/gradio.js`;
  //   document.head.appendChild(gradioScript);
  // }, [])
  return (
    <Provider store={Store} >
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path='/' element={<HomePage />} ></Route>

        </Route>
        <Route path='/welcome' element={<RegisterAndLogin />} ></Route>
        <Route path='/login' element={<Login />} ></Route>
        <Route path='/register' element={<Register />} ></Route>
        <Route path='/share' element={<ShareUrl />} ></Route>
        <Route path='/gallery' element={<Gallery />} ></Route>
        <Route path='/callback' element={<Callback />} ></Route>
        <Route path='/activate' element={<Authorize />} ></Route>
        <Route path='/mobile/:mobile' element={<Mobile />} ></Route>
        <Route path='/reset_password' element={<ResetPass />} ></Route>
        <Route path='/registermobile' element={<RegisterMobile />} ></Route>
        <Route path='/password/:mail' element={<Password />} ></Route>
      </Routes>
    </Provider>
  );
}

export default App;
