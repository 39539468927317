import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEmailNotification } from '../features/HistorySlice'
import { Switch } from '@headlessui/react'

const EmailSwitch = () => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.id);
    // const darkModeState = useSelector(state => state.history.darkModeState)
    const emailNotification = useSelector((state) => state.history.emailNotification);

    // const [enabled, setEnabled] = useState()


    useEffect(() => {
        // console.log(emailNotification)
    }, [emailNotification])

    // const checkEmail = async () => {
    //     var isEnable = await localStorage.getItem('emailNotification');
    //     setEnabled(isEnable == "true" ? true : false);
    // }
    const setEnabled = (value) => {
        var myHeaders = new Headers();
        myHeaders.append("TOKEN", localStorage.getItem("session-token"));
        var formdata = new FormData();
        formdata.append("enable_email_notification", value);
        var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
            credentials: "include",
        };

        fetch("/api/v1/users/settings/" + userId, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                dispatch(setEmailNotification({
                    value: value
                }))
            })
            .catch((error) => { console.log("error", error); });

    }


    return (

        <Switch
            checked={emailNotification}
            onChange={(value) => {
                setEnabled(value)
            }}
            className={`${emailNotification ? 'bg-[#10A37F]' : 'bg-[#D9D9E3]'}
          relative inline-flex h-[38px] scale-50 w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={`${emailNotification ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
        </Switch>
    )
}

export default EmailSwitch