import React, { useState } from 'react'
import {
    WhatsappIcon,
    WhatsappShareButton,
    TwitterIcon,
    TwitterShareButton,
    RedditIcon,
    XIcon,
    RedditShareButton,
    FacebookShareButton,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon
} from "react-share";
import { MdOutlineContentCopy } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
export default function ShareBox({ setShare, app_id, app_name }) {
    const [copied, setCopied] = useState(false);
    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        };
    }
    return (
        <div style={{ width: "100%", height: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.8)', top: 0, left: 0, zIndex: 999, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
            <div className="relative z-[250] rounded-md bg-[#202123] shadow-2xl p-4" style={{ width: '100%', maxWidth: 380 }}>
                <div>
                    <p className="text-center font-bold mb-3 dark:text-white">Share</p>
                    <button
                        onClick={() => setShare(false)}
                        className="p-2 dark:text-white absolute right-0 top-2 rounded-lg"
                    >
                        <RxCross1></RxCross1>
                    </button>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                            // style={{ backgroundColor: "white" }}
                            className="p-3 focus:outline-none w-full rounded-sm"
                            type="text"
                            disabled
                            value={document.location.origin + "/apps/" + app_id}
                            style={{ flex: 1, marginRight: 10 }}
                        />
                        <button
                            id="copyBtn"
                            onClick={(e) => {
                                // navigator.clipboard.writeText(document.location.origin+"/apps/" + text);
                                copyToClipboard(document.location.origin + "/apps/" + app_id)
                                setCopied(true);
                                setTimeout(() => {
                                    setCopied(false);
                                    setShare(false);
                                }, 1000);
                            }}
                            className={`bg-[#343541] ${copied ? "text-sm" : "text-xl"
                                } grid place-items-center text-white w-[3.5rem] h-[3.5rem] rounded-sm relative`}
                        >
                            {!copied && <MdOutlineContentCopy />}
                            {copied && "copied!"}
                        </button>
                    </div>
                    <div className="w-full flex justify-between h-full items-center" style={{ marginTop: 20, marginBottom: 20 }}>

                        <RedditShareButton title={`Check out ${app_name} - an AI app I created with Appstorm!\n`} url={document.location.origin + "/apps/" + app_id}>
                            <RedditIcon round size={50}></RedditIcon>
                        </RedditShareButton>
                        <FacebookShareButton hashtag={`${app_name}\n`} quote={`Check out ${app_name} - an AI app I created with Appstorm!\n`} url={document.location.origin + "/apps/" + app_id}>
                            <FacebookIcon round size={50}></FacebookIcon>
                        </FacebookShareButton>
                        <TelegramShareButton title={`Check out ${app_name} - an AI app I created with Appstorm!\n`} url={document.location.origin + "/apps/" + app_id}>
                            <TelegramIcon round size={50}></TelegramIcon>
                        </TelegramShareButton>
                        <TwitterShareButton title={`Check out ${app_name} - an AI app I created with Appstorm!\n`} url={document.location.origin + "/apps/" + app_id}>
                            <XIcon round size={50}></XIcon>
                        </TwitterShareButton>
                        <WhatsappShareButton title={`Check out ${app_name} - an AI app I created with Appstorm!\n`} url={document.location.origin + "/apps/" + app_id}>
                            <WhatsappIcon round size={50}></WhatsappIcon>
                        </WhatsappShareButton>

                    </div>
                </div>
            </div>
        </div>
    )
}
