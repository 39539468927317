import React from 'react'
import { setToogle } from '../features/HistorySlice';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from "../CustomHooks/useWindowHeightAndWidth";

const HistoryComp = ({ deleteFun, id, initialText, changeChat, visible, deleted }) => {
    const { width } = useWindowDimensions();

    const dispatch = useDispatch()
    const currentChatId = useSelector(state => state.history.currChat?.id)

    return (
        <div
            style={{ cursor: "pointer", display: deleted ? 'none' : visible ? 'block' : 'none' }}
            className={`p-3 flex gap-3 overflow-ellipsis hover:bg-[#353740] ${(currentChatId === id) && 'bg-[#353740]'} items-center rounded w-full text-start text-white text-sm`}
            onClick={() => {
                if (width < 820) {
                    dispatch(setToogle({ toogle: false }));
                }
                changeChat(id);
                // removeFullscreen()
            }}
        >
            {/* <FiMessageSquare /> */}
            {
                <p className="flex-1 overflow-ellipsis overflow-hidden line-clamp-1">
                    {initialText}
                </p>
            }
            {/* {
                currentChatId === id ?
                    rename ?
                        <div className='flex items-center gap-2'>
                            <button onClick={(e) => {
                                e.stopPropagation()
                                // setShouldDelete(false)
                            }} className='text-xl text-[#AAAAB5] hover:text-white scale-75'><RxCross1></RxCross1></button>
                        </div>
                        :
                        <button onClick={(e) => {
                            e.stopPropagation() // will handle event bubbling.
                            // setShouldDelete(true)
                        }} className='text-xl text-[#AAAAB5] hover:text-white'><MdOutlineDelete></MdOutlineDelete></button>
                    : ''
            } */}
        </div>
    )
}

export default HistoryComp