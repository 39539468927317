var myHeaders = new Headers();
myHeaders.append("Accept", "application/json");

var requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
  credentials: "include",
};
//http://172.18.0.3:8080/api/v1/session
//http://app-storm-backend:8080/api/v1
export const fetchToken = (callback) => {
  fetch("/api/v1/session", requestOptions)
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      // console.log(result)
      try {
        if (result) {
          localStorage.setItem("session-token", JSON.parse(result).data.token);
          callback({ status: 'success' });
        }
        else if (result === '') {
          callback({ status: 'success' });
        }
        else {
          callback({ status: 'error' });
        }
      }
      catch (e) {
        callback({ status: 'error', message: e });
      }

    })

    .catch((error) => { console.log("error", error); callback({ status: 'error' }); });
};
