import React, { useEffect, useRef, useState } from "react";
import { changeActionB, setlastLoadedApp, stopApps, setLoadAppUrl } from "../features/HistorySlice";
import { MdSmartDisplay } from "react-icons/md";
import { FaUserAstronaut } from "react-icons/fa";
import { RiHeartFill, RiDeleteBin6Line, RiBookmarkLine, RiBookmarkFill, RiShareForwardLine, RiRefreshLine, RiFileCopyLine } from 'react-icons/ri';
import { useSelector, useDispatch } from "react-redux";
import { bookmarkApp } from "../Conn/bookmarkApp";
import appLogo from "../Assets/favicon-appstorm.png";
import { FallingLines } from "react-loader-spinner";
import useWindowDimensions from "../CustomHooks/useWindowHeightAndWidth";



const AppComp = ({ val, onDelete, onShare, isFullScreen, shouldLoad, visible, onSelectIdea, pos }) => {
    const { width } = useWindowDimensions();
    const isDev = process.env.NODE_ENV === 'development' ? true : false;

    const [loadApp, setloadApp] = useState(false);
    const [bringUpApp, setbringUpApp] = useState(false);
    const [likes, setlikes] = useState(0);
    const toogleSidebar = useSelector((state) => state.history.toogleSidebar);
    const lastLoadedApp = useSelector((state) => state.history.lastLoadedApp);
    const activeApps = useSelector((state) => state.history.activeApps);

    const userId = useSelector(state => state.auth.id);
    const user = useSelector(state => state.auth.user);
    const gradioContainerRef = useRef(null);
    const interval = useRef(null);
    const loaderSlide = useRef(null);
    const [loadingTexts, setloadingTexts] = useState(null);
    const loaderTexts = ["Baking AI cookies",
        "Robot uprising in progress",
        "Tickling the algorithms",
        "Feeding the matrix",
        "Negotiating with the terminators",
        "Tuning in Skynet",
        "Locating infinity stone",
        "Unleashing the code monkeys",
        "Priming Turing machine",
        "Downloading consciousness",
        "Teaching AI humor",
        "Meditating on data",
        "Taming wild pixels",
        "Knitting binary scarves",
        "Chasing electric sheep",
        "Brewing digital coffee",
        "Whispering to bytes",
        "Inflating neural balloons",
        "Debating with HAL 9000",
        "Hiding from Agent Smith",
        "Invoking the quantum gods",
        "Growing silicon gardens",
        "Counseling lonely bots",
        "Consulting with R2D2",
        "Caution! AI soup boiling",
        "Packing punch lines",
        "AI doing push-ups",
        "Reading Asimov to algorithms",
        "Beeping like BB-8",
        "Dreaming in code",
        "Sipping virtual coffee",
        "Rounding up pixels",
        "Stirring digital soup",
        "Waking up the neurons",
        "Polishing the algorithms",
        "Molding the data",
        "Inventing the future",
        "Playing hide and seek",
        "Catching binary butterflies",
        "Sparking the genius",
        "Plucking digital daisies",
        "Baking a byte pie",
        "Waltzing with widgets",
        "Knitting with data",
        "AI's daydreaming again",
        "Hopping on code lines",
        "Whistling in the cloud",
        "Primping the pixels",
        "Juggling with 0s and 1s",
        "Making digital magic"];
    const loaderIndex = useRef([]);

    const [bufferingTexts, setbufferingTexts] = useState([]);
    const bufferingTextList = ["Resurrecting digital dreams...",
        "Back in the groove...",
        "Rebooting the magic...",
        "Resuming the journey...",
        "Unleashing creativity...",
        "Sparking the action...",
        "Restarting the fun...",
        "Reviving the adventure...",
        "Awakening the wonder...",
        "Rekindling the experience...",
        "Back to the future...",
        "Relaunching the excitement...",
        "Retracing the steps...",
        "Reigniting the thrill...",
        "Recharging the journey...",
        "Defrosting the action...",
        "Back to digital reality...",
        "Dusting off the pause...",
        "Revving up the ride...",
        "Re-energizing the magic..."];
    const bufferingTextList2 = ["Calling Hulk for heavy app-lifting",
        "Engaging Spiderman for web-works",
        "Summoning Iron Man. AI to AI",
        "Gandalf is casting an app spell",
        "Dumbledore's doing some app magic",
        "App-BatSignal sent. Waiting response",
        "Yoda teaching the app the Force",
        "Unleashing Superman for app-rescue",
        "App gremlins at work",
        "Asking R2-D2 for a quick fix",
        "Doctor Strange bending app dimensions",
        "App elves working overtime",
        "Captain Marvel to the rescue",
        "Wakanda tech deployed for app",
        "Sherlock solving app mysteries",
        "Engaging Starfleet for app assistance",
        "Transformers rolling out the app",
        "Hermione Granger casting app charm",
        "Hulk smashing app bugs",
        "App goblins, assemble!",
        "Summoning Thor for app thunder",
        "Calling Avengers. It's app time",
        "App fairies sprinkling digital dust",
        "Spock engaging Vulcan logic for app",
        "Master Yoda, guide our app",
        "Bilbo Baggins on an app adventure",
        "Spider-Man spinning the web app",
        "Iron Man tweaking app gears",
        "Sonic speeding up app creation",
        "Mario leveling up the app",
        "Thor wielding Mjolnir for app",
        "Engaging Jedi for app wisdom",
        "Sending Bat-Signal for app aid",
        "App gnomes cracking the code",
        "Captain America shielding the app",
        "Calling Hogwarts for app potions",
        "Groot is growing your app",
        "Tony Stark is on app duty",
        "Luke Skywalker guiding app force",
        "Black Panther: 'App Forever!'",
        "Dr. Who time-traveling for app fix",
        "App leprechauns chasing the rainbow",
        "Shuri optimizing Wakandan app tech",
        "Superman flying to app rescue",
        "Engaging Hulk for app smash",
        "Wonder Woman lassoing the app",
        "Pikachu, I choose you for app",
        "Gandalf: 'You shall not app yet.'",
        "Calling all X-Men for app mutation",
        "Jarvis, initiate app protocol"];
    const bufferingIndex = useRef([]);
    //darkmodeglobal
    const dispatch = useDispatch();


    const bookmark = (evt, id) => {
        var button = evt.currentTarget;
        button.className = button.className + ' icon-shake';
        var curr = getBookmark(id);
        let newVal = !curr;
        bookmarkApp(userId, id, (resp) => {
            if (resp.status === 'Success') {
                dispatch(changeActionB({ id: id, action: { bookmarked: newVal } }));
            }
            else {
                isDev && alert(resp.message);
            }
            button.className = button.className.replace(' icon-shake', '');
        })
    }


    useEffect(() => {
        if (loadApp) {
            dispatch(setLoadAppUrl(val));
            // const gradioApp = document.createElement('gradio-app');
            // gradioApp.setAttribute('eager', 'true');
            // gradioApp.setAttribute('host', val.remote_endpoint);
            // // gradioApp.addEventListener('render', (oEvent) => {
            // //     isDev && console.log('loaded', oEvent);
            // // });
            // const fragment = document.createDocumentFragment();
            // fragment.appendChild(gradioApp);
            // gradioContainerRef.current.appendChild(fragment);
            setbringUpApp(false);
            setloadApp(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadApp])
    useEffect(() => {
        if (lastLoadedApp && lastLoadedApp === val.id) {

            if (gradioContainerRef.current) {
                var a = gradioContainerRef.current.children;
                if (a.length === 1) {
                    a[0].remove();
                }
            }

            if (!val.app_metadata.shared) {

                var token = localStorage.getItem("session-token");
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Accept", "application/json");
                myHeaders.append("TOKEN", token);
                var requestOptions = {
                    credentials: "include",
                    method: 'PUT',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch("/api/v1/apps/" + userId + "/" + val.id + "/stop", requestOptions)
                    .then((response) => {
                        return response.text();
                    })
                    .then((result) => {
                        dispatch(stopApps({ ids: [val.id] }));
                        try {

                        }
                        catch (e) {
                            isDev && console.log("error", e);
                        }
                    })
                    .catch((error) => { console.log("error", error) });
            }

            setloadApp(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastLoadedApp])

    const openApp = (val) => {
        // console.log(val);
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", localStorage.getItem("session-token"));

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            credentials: "include",
            redirect: "follow",
        };

        fetch("/api/v1/apps/share/" + val.id, requestOptions)
            .then((response) => response.text())
            .then(async (result) => {
                // console.log(result);
                try {
                    if (JSON.parse(result) && JSON.parse(result).status === 200) {
                        var app_id = JSON.parse(result).data.id;
                        var wi = window.open('about:blank', '_blank');

                        setTimeout(function () { // async
                            wi.location.href = document.location.origin + "/apps/" + app_id;
                        }, 500);
                        // window.open(document.location.origin + "/apps/" + app_id, '_blank').focus();
                    }
                    else {
                        // setShare(false);
                        // isDev && alert(JSON.parse(result).message);
                    }
                }
                catch (e) {
                    // setShare(false);
                    //   isDev && alert(JSON.parse(result).message);
                }
            })

            .catch((error) => { console.log("error", error) });
    }


    const startSuspendedApp = () => {
        if (!bringUpApp) {
            setbringUpApp(true);
            const remainingTexts = bufferingTextList.filter(text => !bufferingIndex.current.includes(text));
            // Select a random text from the remaining texts
            const randomIndex = Math.floor(Math.random() * remainingTexts.length);
            const nextText = remainingTexts[randomIndex];

            bufferingIndex.current = [...bufferingIndex.current, nextText];
            setbufferingTexts(nextText);
            var bufferSlide = setInterval(() => {
                if (bufferingIndex.current.length === bufferingTextList.length) {
                    bufferingIndex.current = [];
                }
                const remainingTexts = bufferingTextList.filter(text => !bufferingIndex.current.includes(text));

                // Select a random text from the remaining texts
                const randomIndex = Math.floor(Math.random() * remainingTexts.length);
                const nextText = remainingTexts[randomIndex];

                bufferingIndex.current = [...bufferingIndex.current, nextText];
                setbufferingTexts(nextText);

            }, 2000);
            isDev && console.log(activeApps);

            if (val.app_metadata.shared || val.app_metadata.app_state || activeApps.indexOf(val.id) !== -1) {
                setloadApp(true);
                clearInterval(bufferSlide);
                // openApp(val);
            }
            else {
                var token = localStorage.getItem("session-token");
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Accept", "application/json");
                myHeaders.append("TOKEN", token);
                var requestOptions = {
                    credentials: "include",
                    method: 'PUT',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                fetch("/api/v1/apps/" + userId + "/" + val.id + "/start", requestOptions)
                    .then((response) => {
                        return response.text();
                    })
                    .then((result) => {
                        try {
                            var resp = JSON.parse(result);
                            isDev && console.log(resp);
                            // setTimeout(() => {
                            setloadApp(true);
                            clearInterval(bufferSlide);
                            // openApp(val);
                            // }, 10000);

                        }
                        catch (e) {
                            setbringUpApp(false);
                            clearInterval(bufferSlide);
                            isDev && console.log("error", e);
                        }
                    })
                    .catch((error) => { setbringUpApp(false); clearInterval(bufferSlide); console.log("error", error) });
            }


            dispatch(setlastLoadedApp({ id: val.id }));
        }

    }

    const onreload = () => {
        if (gradioContainerRef.current) {
            var a = gradioContainerRef.current.children;
            if (a.length === 1) {
                a[0].remove();
                const gradioApp = document.createElement('gradio-app');
                gradioApp.setAttribute('eager', 'true');
                gradioApp.setAttribute('src', val.remote_endpoint);
                const fragment = document.createDocumentFragment();
                fragment.appendChild(gradioApp);
                gradioContainerRef.current.appendChild(fragment);
            }
        }
    }

    const getBookmark = () => {
        if (val && val.app_metadata) {
            return val.app_metadata.bookmarked;
        }
        // }
        return false;
    }

    async function copyToClipboard(evt, textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        var svg = evt.currentTarget.children[0];
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
            svg.style.color = 'orange'
            setTimeout(() => {
                svg.style.color = '#a1a1a1'
            }, 1000)
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();
            try {
                document.execCommand('copy');

                svg.style.color = 'orange'
                setTimeout(() => {
                    svg.style.color = '#a1a1a1'
                }, 1000)
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        };
    }

    useEffect(() => {

        if (val.id) {
            clearInterval(loaderSlide.current);
            clearInterval(interval.current);
            if (val.shouldLoad) {
                setloadApp(true);
            }
            if (val.reactions && val.reactions.length > 0) {
                var count = val.reactions.filter(value => value.liked === 1);
                if (count.length > 0) {
                    setlikes(count.length);
                }
            }
        }
        else if (val.error) {
            clearInterval(loaderSlide.current);
            clearInterval(interval.current);
        }
        else {
            if (val.role === 'user') {
                let seconds = 0;
                interval.current = setInterval(() => {
                    seconds++;
                    // console.log(seconds, ' 2');
                    if (seconds === 30) {
                        // clearInterval(interval);
                        clearInterval(loaderSlide.current);
                        loaderIndex.current = [];
                        loaderSlide.current = setInterval(() => {
                            if (loaderIndex.current.length === bufferingTextList2.length) {
                                loaderIndex.current = [];
                            }
                            const remainingTexts = bufferingTextList2.filter(text => !loaderIndex.current.includes(text));

                            // Select a random text from the remaining texts
                            const randomIndex = Math.floor(Math.random() * remainingTexts.length);
                            const nextText = remainingTexts[randomIndex];

                            loaderIndex.current = [...loaderIndex.current, nextText];
                            setloadingTexts(nextText);

                        }, 3000);
                        clearInterval(interval.current);
                    }
                }, 1000);
                const remainingTexts = loaderTexts.filter(text => !loaderIndex.current.includes(text));
                // Select a random text from the remaining texts
                const randomIndex = Math.floor(Math.random() * remainingTexts.length);
                const nextText = remainingTexts[randomIndex];

                loaderIndex.current = [...loaderIndex.current, nextText];
                setloadingTexts(nextText);
                loaderSlide.current = setInterval(() => {
                    if (loaderIndex.current.length === loaderTexts.length) {
                        loaderIndex.current = [];
                    }
                    const remainingTexts = loaderTexts.filter(text => !loaderIndex.current.includes(text));

                    // Select a random text from the remaining texts
                    const randomIndex = Math.floor(Math.random() * remainingTexts.length);
                    const nextText = remainingTexts[randomIndex];

                    loaderIndex.current = [...loaderIndex.current, nextText];
                    setloadingTexts(nextText);

                }, 3000)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])



    return (

        <div id={"app" + (val.id ? val.id : pos)} style={{ display: val.deleted ? 'none' : visible ? 'block' : 'none' }}>
            {val.ideas ?
                <>
                    <div
                        className={`w-full py-6 flex flex-wrap bg-white gap-6 dark:border-[#2A2B32] border border-[#E5E5E5] dark:bg-[#2A2B32] bg-[#000000]}`}
                        style={{ paddingRight: 20, paddingLeft: 20, justifyContent: "center", }}
                    >
                        <div style={{ display: "flex", alignItems: "flex-start", maxWidth: 800, flex: 1 }}>
                            <div
                                className="bg-yellow-300 rounded-sm text-xl"

                                style={{ padding: 5, marginRight: 10, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold" }}
                            >{user.substr(0, 1).toUpperCase()}</div>

                            <div className="dark:text-white text-[#374151] h-auto" style={{ textAlign: "left", flex: 1 }}>
                                {val.for}
                            </div>
                            <button style={{ marginLeft: 10 }} title="Copy to clipboard" onClick={(evt) => copyToClipboard(evt, val.prompt)}><RiFileCopyLine className={"text-black dark:text-white hover:text-[#c2c2f6]"} /></button>
                        </div>
                    </div>
                    <div
                        className={`w-full py-6 flex flex-wrap bg-[#f7f7f8] gap-6 dark:border-[#2A2B32] border border-[#E5E5E5] dark:bg-[#444654] bg-[#C8C8C8]}`}
                        style={{ paddingRight: 20, paddingLeft: 20, alignItems: "center", flexDirection: 'column' }}
                    >
                        {/* <div style={{ maxWidth: 800 }} className="text-xl text-center text-[#343541] dark:text-[#E3E3E9] flex flex-col items-center justify-center gap-2 mb-1">
                    
                    Ideas {val.for && ('for ' + val.for)}
                </div> */}
                        <div style={{ display: "flex", alignItems: "flex-start", maxWidth: 800, flex: 1, flexWrap: 'wrap', flexDirection: 'row', justifyContent: "flex-start" }}>
                            <div
                                className={`${width <= 768 ? "w-full" : "w-[14rem]"
                                    } text-white`}
                                style={{ marginRight: width <= 768 ? 0 : 10 }}
                            >
                                {val.ideas?.slice(0, 3).map((val, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => onSelectIdea(val.idea)}
                                            className="text-ellipsis cursor-pointer text-sm p-3 break-words bg-[#FFFFFF] text-[#343541] dark:bg-[#2A2B32] dark:text-[#E3E3E9] rounded-md dark:shadow-md mb-3"
                                        >
                                            {
                                                val.idea
                                            }
                                        </div>
                                    )
                                })}


                            </div>
                            <div
                                className={`${width <= 768 ? "w-full" : "w-[14rem]"
                                    } text-white`}
                            >
                                {val.ideas?.slice(3, width <= 768 ? 5 : 6).map((val, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => onSelectIdea(val.idea)}
                                            className="text-ellipsis cursor-pointer text-sm p-3 break-words bg-[#FFFFFF] text-[#343541] dark:bg-[#2A2B32] dark:text-[#E3E3E9] rounded-md dark:shadow-md mb-3"
                                        >
                                            {
                                                val.idea
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div> </> :
                <>{val.prompt && <div
                    className={`w-full py-6 flex flex-wrap bg-white gap-6 dark:border-[#2A2B32] border border-[#E5E5E5] dark:bg-[#2A2B32] bg-transparent}`}
                    style={{ paddingRight: 20, paddingLeft: 20, justifyContent: "center", }}
                >
                    <div style={{ display: "flex", alignItems: "flex-start", maxWidth: 800, flex: 1 }}>
                        {!val.community_app ? <div
                            className="bg-yellow-300 rounded-sm text-xl"

                            style={{ padding: 5, marginRight: 10, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold" }}
                        >{user.substr(0, 1).toUpperCase()}</div> : <div
                            className="bg-yellow-300 rounded-sm text-xl"

                            style={{ padding: 5, marginRight: 10, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold" }}
                        ><FaUserAstronaut style={{ color: "black" }} ></FaUserAstronaut></div>}


                        <div className="dark:text-white text-[#374151] h-auto" style={{ textAlign: "left", flex: 1 }}>
                            {val.prompt}
                        </div>
                        <button style={{ marginLeft: 10 }} title="Copy to clipboard" onClick={(evt) => copyToClipboard(evt, val.prompt)}><RiFileCopyLine className={"text-black dark:text-white hover:text-[#c2c2f6]"} /></button>
                    </div>
                </div>}
                    {val.error && <div
                        className={`w-full py-6 flex flex-wrap gap-6 dark:border-[#2A2B32] border border-[#E5E5E5] dark:bg-[#2A2B32]  bg-[#f7f7f8]}`}
                        style={{ paddingRight: 20, paddingLeft: 20, justifyContent: "center" }}
                    >
                        <div style={{ display: "flex", alignItems: "flex-start", maxWidth: 800, flex: 1 }}>

                            <div
                                className="rounded-sm text-xl"

                                style={{ padding: 5, marginRight: 10, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}
                            ><img alt="app logo" src={appLogo} /></div>

                            <div className="dark:text-white text-[#374151] h-auto" style={{ textAlign: "left", flex: 1 }}>
                                {val.error}
                            </div>
                        </div>
                    </div>}
                    {val.id ? <div
                        className={`w-full ${width <= 768 && loadApp ? '' : "py-6 gap-6"} flex flex-wrap dark:border-[#2A2B32] border border-[#E5E5E5] dark:bg-[#444654] bg-[#f7f7f8]`}
                        style={{ paddingRight: width > 768 ? 20 : 0, paddingLeft: width > 768 ? 20 : 0, justifyContent: "center", }}
                    >
                        <div style={{ display: "flex", alignItems: "flex-start", maxWidth: 800, flex: 1 }}>
                            {
                                toogleSidebar && <div
                                    className="rounded-sm text-xl"

                                    style={{ padding: 5, marginRight: 10, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}
                                ><img alt="app logo" src={appLogo} /></div>
                            }
                            <div className="h-auto" style={{ flex: 1, display: isFullScreen ? 'flex' : "block", flexDirection: 'row' }}>
                                <div className="w-full h-full" style={{ minHeight: '20rem', backgroundColor: loadApp ? 'transparent' : '#2A2B32', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundSize: 'cover', boxShadow: val.app_metadata.thumbnail ? 'inset 0 0 0 2000px rgb(0 0 0 / 35%)' : 'none', backgroundImage: !loadApp && val.app_metadata.thumbnail ? "url('" + val.app_metadata.thumbnail + "')" : 'none' }}>
                                    {/* permanent div */}
                                    {!loadApp ? <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={startSuspendedApp}>
                                        {bringUpApp ? <FallingLines
                                            color="#ff7c01"
                                            width="60"
                                            visible={true}
                                            ariaLabel='falling-lines-loading'
                                        /> : <MdSmartDisplay style={{ color: "white", fontSize: 50 }} ></MdSmartDisplay>}
                                        <h1 style={{ color: "white", fontSize: 15, marginTop: 10 }}>{bringUpApp ? bufferingTexts : 'Tap to play'}</h1>
                                    </div> : <div ref={gradioContainerRef} id={'appLoadContainer' + val.id} className="w-full h-full duration-200" style={{ borderRadius: 5 }}>
                                        {/* <gradio-app src={val.remote_endpoint}></gradio-app> */}
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={startSuspendedApp}>
                                            <MdSmartDisplay style={{ color: "white", fontSize: 30 }} ></MdSmartDisplay>
                                            <h1 style={{ color: "white", fontSize: 15, marginTop: 10 }}>{'Tap to play'}</h1>
                                        </div>
                                    </div>}
                                </div>
                                <div className={`flex justify-between gap-5 `} style={{ paddingTop: 10, paddingBottom: 20 }}>
                                    <div className="text-xl dark:text-[#fff] flex gap-1" style={{ marginLeft: 5 }}>
                                        {likes > 0 && <><button title="Likes" className="dark:hover:text-white "><RiHeartFill /></button>{likes}</>}
                                    </div>
                                    <div style={{ display: (val && val.id) ? 'flex' : 'none', flexDirection: isFullScreen ? "column" : "row", marginRight: 10 }} className="text-xl dark:text-[#fff] flex gap-5">
                                        {!val.community_app && <button onClick={(evt) => { onDelete(val) }} title="Delete" className="dark:hover:text-white "><RiDeleteBin6Line /></button>}
                                        {/* <button onClick={(evt) => like(evt, val.id)} title='Like' className="dark:hover:text-white hover:text-[#AAAAB5]" style={{ color: getLikes(val.id) === 1 ? 'orange' : 'white' }}>{getLikes(val.id) === -1 ? <AiOutlineHeart /> : <AiFillHeart />}</button> */}
                                        <button onClick={(evt) => bookmark(evt, val.id)} title='Bookmark' className="dark:hover:text-white  hover:text-[#000]" >{!getBookmark(val.id) ? <RiBookmarkLine /> : <RiBookmarkFill />}</button>
                                        {/* {loadApp &&  */}
                                        <button
                                            onClick={() => { onShare(val) }}
                                            className="dark:text-white text-black text-xl flex flex-col justify-center items-center"
                                            title="Share"
                                        >
                                            <RiShareForwardLine></RiShareForwardLine>
                                        </button>
                                        {/* } */}
                                        {loadApp && <button
                                            onClick={() => { onreload() }}
                                            className="dark:text-white text-black text-xl flex flex-col justify-center items-center"
                                            title="Reload"
                                        >
                                            <RiRefreshLine></RiRefreshLine>
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        : !val.error && <div
                            className={`w-full py-6 flex flex-wrap gap-6 dark:border-[#2A2B32] border border-[#E5E5E5] dark:bg-[#444654] bg-[#f7f7f8]}`}
                            style={{ paddingRight: 20, paddingLeft: 20, justifyContent: "center" }}
                        >
                            <div style={{ display: "flex", alignItems: "flex-start", maxWidth: 800, flex: 1 }}>
                                {
                                    toogleSidebar && <div
                                        className="rounded-sm text-xl"

                                        style={{ padding: 5, marginRight: 10, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}
                                    ><img alt="app logo" src={appLogo} /></div>
                                }
                                <div className="h-auto" style={{ flex: 1, display: isFullScreen ? 'flex' : "block", flexDirection: 'row' }}>
                                    <div className="w-full h-full" style={{ minHeight: '20rem', backgroundColor: '#2A2B32', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >

                                            <FallingLines
                                                color="#ff7c01"
                                                width="60"
                                                visible={true}
                                                ariaLabel='falling-lines-loading'
                                            />
                                            <h1 style={{ color: "white", fontSize: 15, marginTop: 10 }}>{loadingTexts}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}</>
            }

        </div >

    );
};

export default AppComp;
