export const deleteApp = (userid, appid, callback) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", localStorage.getItem("session-token"));


    var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
    };

    fetch("/api/v1/apps/" + userid + "/" + appid, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            // console.log(result);
            try {
                if (
                    JSON.parse(result) &&
                    JSON.parse(result).status &&
                    JSON.parse(result).status === 200
                ) {
                    callback({ status: "Success" });
                } else {
                    callback({ status: "Error", message: JSON.parse(result).message });
                }
            }
            catch (e) {
                callback({ status: "Error", message: e });
            }
        })
        .catch((error) => { callback({ status: "Error" }); console.log("error", error) });
};
