export const deleteChat = (chat_session_id, callback) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", localStorage.getItem('session-token'));

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(`api/v1/app_builder/session/chat/${chat_session_id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            // console.log(result);
            callback()
        })
        .catch(error => console.log('error', error));
}