import React, { useEffect, useState, useLayoutEffect } from 'react'
import { AiOutlineHeart } from "react-icons/ai";
import Layout from 'react-masonry-list'
import { MdSmartDisplay } from "react-icons/md";
export default function Gallery({ changeMode }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [filteredData, setfilteredData] = useState([]);

    const handleMenuItemClick = (index, item) => {
        setActiveIndex(index);
        var fData = IMGS.filter(val => val.cat === item.cat);
        setfilteredData([...fData])
    };

    const menuItems = [{
        title: 'Trending',
        cat: '0'
    }, {
        title: "People's Favourite",
        cat: '1'
    }, {
        title: 'For You',
        cat: '2'
    }];
    const IMGS = [
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '0', title: 'GenDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '0', title: 'ImageGen', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/app_7894c5dd16a1463b.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '0', title: 'GenDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/app_5efda26d5d6f32a9.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '1', title: 'VirtualDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '0', title: 'GenDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '1', title: 'VirtualDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '2', title: 'GenDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '1', title: 'VirtualDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '0', title: 'GenDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting. The city's tall buildings would be silhouetted against a colorful sky, creating a captivating and dynamic scene.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },
        { endpoint: 'https://fitbotique_service_1706715432.devapps.appstorm.ai', cat: '2', title: 'GenDec', text: "For the homepage banner, an image of a vibrant city skyline at sunset would be fitting.", image: 'https://d3o23lul7dx07b.cloudfront.net/fitbotique_269ff880.png' },

    ];
    const _getColCount = (size) => {
        if (size < 720) {
            return 1;
        }
        if (size < 1024) {
            return 2;
        }


        return 3;
    };
    const [colCount, setColCount] = useState(_getColCount(window.innerWidth));
    useLayoutEffect(() => {
        const onResize = () => {
            setColCount(_getColCount(window.innerWidth));
        };
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);
    useEffect(() => {
        var fData = IMGS.filter(val => val.cat === '0');
        setfilteredData([...fData]);
        const gradioVersion = '3.48.0'; // Assuming your API response has a 'version' field

        // Replace the {VERSION} placeholder with the fetched Gradio version
        const gradioScript = document.createElement('script');
        gradioScript.type = 'module';
        gradioScript.src = `https://gradio.s3-us-west-2.amazonaws.com/${gradioVersion}/gradio.js`;
        document.head.appendChild(gradioScript);
    }, [])


    return (
        <div className="flex bg-[#343541]" style={{ flexDirection: 'column', display: 'flex', minHeight: '100vh', paddingTop: 50, width: '100%' }}>
            <ul className="menu">
                {menuItems.map((item, index) => (
                    <li
                        key={index}
                        style={{ color: 'white', fontSize: 20 }}
                        className={activeIndex === index ? 'active' : ''}
                        onClick={() => handleMenuItemClick(index, item)}
                    >
                        <div>
                            <span>{item.title}</span>
                            <div className='border-underline'></div>
                        </div>
                    </li>
                ))}
                <div className={`underline ${activeIndex !== null ? 'active' : ''}`} />
            </ul>
            <div className="App" style={{ flexDirection: 'column', display: 'flex', minHeight: '100vh', paddingTop: 50, width: '100%', display: 'flex', backgroundColor: '#343541' }}>
                <Layout
                    colCount={colCount}
                    gap={30}
                    items={filteredData.map((el, i) =>
                    (
                        <div key={i} className='box' style={{ margin: 10, boxShadow: 'rgba(0, 0, 0, 0.18) 0px 8px 10px 0px', borderRadius: 10, background: 'white' }}>
                            <div style={{ backgroundSize: 'cover', backgroundImage: "url('" + el.image + "')", height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px 10px 0px 0px', borderRadius: 6 }} id={'appThumbContainer' + i} >
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={(oEvent) => {
                                    const gradioApp = document.createElement('gradio-app');
                                    document.getElementById('appThumbContainer2' + i).style.display = 'block';
                                    document.getElementById('appThumbContainer' + i).style.display = 'none';
                                    gradioApp.setAttribute('eager', 'true');
                                    gradioApp.setAttribute('initial_height', '0px');
                                    gradioApp.setAttribute('autoscroll', 'true');
                                    gradioApp.setAttribute('host', el.endpoint);
                                    gradioApp.addEventListener('render', (oEvent) => {
                                        // console.log('loaded', oEvent);
                                        let totalHeight = oEvent.target.scrollHeight + 50;
                                        oEvent.target.parentElement.parentElement.style.height = totalHeight + 'px';
                                        oEvent.target.parentElement.parentElement.style.overflowX = 'scroll';
                                        let temp = filteredData;
                                        temp[0].text = temp[0].text;
                                        setfilteredData([...filteredData]);

                                    });
                                    const fragment = document.createDocumentFragment();
                                    fragment.appendChild(gradioApp);
                                    document.getElementById('appLoadContainer' + i).appendChild(fragment);
                                    // resize_ob.observe(fragment);

                                }} >
                                    <MdSmartDisplay style={{ color: "white", fontSize: 50 }} ></MdSmartDisplay>
                                    <h1 style={{ color: "white", fontSize: 15, marginTop: 10 }}>{'Tap to play'}</h1>
                                </div>


                            </div>
                            <div id={'appThumbContainer2' + i} style={{ display: 'none' }}>
                                <div id={'appLoadContainer' + i} >

                                </div>
                            </div>
                            <div id={'appDetailContainer' + i} style={{ padding: 10, textAlign: 'left', }}>
                                <h3 style={{ color: 'black', margin: '10px 0px', fontWeight: 'bold' }}>{el.title}</h3>
                                <p style={{ color: 'black', marginBottom: 10 }}>{el.text}</p>
                                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 100 }}>
                <AiOutlineHeart style={{ color: 'black' }} /> <span style={{ fontSize: 14, marginLeft: 5, color: 'black' }}>3</span>
              </div> */}
                            </div>
                        </div>
                    )
                    )}
                ></Layout>

            </div>
            {/* <div className='createContainer'>
                <button onClick={() => {
                    document.getElementsByClassName('createButton')[0].style.borderRadius = '0.375rem';
                    document.getElementsByClassName('createButton')[0].style.maxWidth = '600px';
                    document.getElementsByClassName('createButton')[0].style.width = '100%';
                    setTimeout(() => {
                        changeMode()
                    }, 800)
                }} className='createButton'>
                    +
                </button>
            </div> */}
        </div>
    )
}
