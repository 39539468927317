import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import appLogo from "../Assets/appLogo.png";
import { useDispatch } from "react-redux";
import { setUserAndId } from "../features/authSlice";
import { TailSpin } from "react-loader-spinner";
import CookiePolicy from "../Components/CookiePolicy";
import Cookies from "js-cookie";
import { Helmet } from 'react-helmet';
const RegisterAndLogin = () => {
  const [cookieAccept, setcookieAccept] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkingLog, setcheckingLog] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  useLayoutEffect(() => {

    var token = localStorage.getItem("session-token");
    var uid = localStorage.getItem("appstorm-user");
    var isCookie = Cookies.get('_consent_appstorm_') === "true" ? true : false;
    setcookieAccept(isCookie);
    if (token && uid) {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", token);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };
      fetch("/api/v1/users/" + uid, requestOptions)
        .then((response) => {
          return response.text();
        })
        .then((result) => {
          // console.log(result);
          try {
            var val = JSON.parse(result);
            if (val.status === 200) {
              setisLoggedIn(true);
              dispatch(
                setUserAndId({
                  email: val.data.user_name ? val.data.user_name : val.data.email,
                  id: val.data.id,
                })
              );
              setTimeout(() => {

                if (isCookie) {
                  navigate("/");
                }

              }, 1500)
            }
            else {
              // fetchToken();
              setcheckingLog(false);
            }
          }
          catch (e) {
            setcheckingLog(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
    else {
      // fetchToken();
      setcheckingLog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = () => {
    navigate("/login");
  }

  const setCookie = () => {
    Cookies.set('_consent_appstorm_', true, { expires: 365 });
    setcookieAccept(true);
    if (isLoggedIn) {
      navigate("/");
    }
  }

  return (
    <div className="w-full bg-[#f6f6f6] flex flex-col justify-center items-center gap-2 text-white" style={{ padding: 20, height: '100%', minHeight: 400 }}>
      <Helmet>
        <title>Welcome To Appstorm.ai</title>
        <meta property="og:title" content='Welcome To Appstorm.ai'></meta>
        {/* <meta property="og:image" content={app?.app.app_metadata.thumbnail}></meta> */}
        <meta property="description" content='Type your idea as a prompt. Or click on the tornado icon to generate an idea'></meta>
        <meta property="og:description" content='Type your idea as a prompt. Or click on the tornado icon to generate an idea'></meta>
        <meta name="twitter:title" content='Welcome To Appstorm.ai' />
        <meta name="twitter:description" content='Type your idea as a prompt. Or click on the tornado icon to generate an idea' />
        {/* <meta name="twitter:image" content={app?.app.app_metadata.thumbnail} /> */}
      </Helmet>
      {cookieAccept &&
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-9PG529H5S0"></script>
          <script>
            {` 
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-9PG529H5S0');
              `}
          </script>
        </Helmet>
      }
      {!cookieAccept && <CookiePolicy setCookie={setCookie} />}
      <img alt="app logo" src={appLogo} style={{ maxWidth: 300 }} />

      {
        checkingLog ? (
          <div>
            <TailSpin
              height="40"
              width="40"
              color="#000000"
              ariaLabel="tail-spin-loading"
              radius="0.1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (<div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h1 className="text-center text-xl" style={{ color: "black" }}>
            Welcome!
          </h1>
          <h1 className="text-center text-xl" style={{ color: "black" }}>
            Login with your account to continue.
          </h1>
          <div className="flex gap-3" style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
            <button
              id="loginId"
              onClick={login}
              className="bg-[#10A37F] font-semibold py-2 px-3 rounded-sm text-sm"
            >
              Log in
            </button>
            <button
              onClick={() => navigate("/register")}
              className="bg-[#10A37F] py-2 px-3 font-semibold rounded-sm text-sm"
            >
              Sign up
            </button>
          </div>
        </div>)

      }

    </div>
  );
};

export default RegisterAndLogin;
