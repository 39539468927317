import React, { useEffect, useState } from "react";
import { loginUser } from "../Conn/login";
import { useDispatch } from "react-redux";
import { setUserAndId } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import appLogo from "../Assets/appLogo.png";
import PasswordInput from "../Components/PasswordInput";
import '../App.css';
import { fetchToken } from "../Conn/session";
import { Helmet } from 'react-helmet';

const Register = () => {
  const [seconds, setSeconds] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isLoading, setIsLoaing] = useState(false)
  const [sentmail, setsentmail] = useState(false)
  const [firstTimeRegistration, setfirstTimeRegistration] = useState(false);
  const [flag, setFlag] = useState(false)
  const isDev = process.env.NODE_ENV === 'development' ? true : false;
  const [errorMsg, seterrorMsg] = useState(" ");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLengthValid, setIsLengthValid] = useState(true);
  const [hasUppercase, setHasUppercase] = useState(true);
  const [hasLowercase, setHasLowercase] = useState(true);
  const [hasDigit, setHasDigit] = useState(true);
  const [hasSpecialChar, setHasSpecialChar] = useState(true);

  const checkPasswordStrength = (password) => {
    // const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=_]).{8,}$/;

    if (password) {
      const minLength = 8;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const digitRegex = /\d/;
      const specialCharRegex = /[@#$%^&+=_]/;

      // Check each rule individually
      setIsLengthValid(password.length >= minLength);
      setHasUppercase(uppercaseRegex.test(password));
      setHasLowercase(lowercaseRegex.test(password));
      setHasDigit(digitRegex.test(password));
      setHasSpecialChar(specialCharRegex.test(password));
    }
    else {
      setIsLengthValid(true);
      setHasUppercase(true);
      setHasLowercase(true);
      setHasDigit(true);
      setHasSpecialChar(true);
    }
  };
  const setPasswordCheck = (newPassword) => {
    seterrorMsg(" ");
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  }


  const registerUser = (registerdata) => {
    setIsLoaing(true);
    fetchToken((val) => {
      if (val.status === 'success') {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", localStorage.getItem("session-token"));

        var formdata = new FormData();
        formdata.append("user_name", registerdata.email);
        formdata.append("email", registerdata.email);
        formdata.append("password", registerdata.password);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          credentials: "include",
          redirect: "follow",
        };

        // previous => http://54.225.31.227:8080
        fetch("/api/v1/register", requestOptions)
          .then((response) => response.text())
          .then((result) => {
            setIsLoaing(false);
            // console.log(result) // if the method is here we can better set the state variables here in this function.
            // setShouldLogin(true)
            try {
              if (JSON.parse(result).status === 201) {
                if (JSON.parse(result).message === 'User account not activated.') {
                  setsentmail(true);
                  setSeconds(20);
                }
                else if (JSON.parse(result).message === 'Activation code sent to email.') {
                  setsentmail(true);
                  setSeconds(20);
                  setfirstTimeRegistration(true);

                  // alert('Please check your mail for activation link');
                  // navigate("/");
                }
                else {
                  setFlag(true);
                }
              }
              else {
                setIsLoaing(false);
                if (JSON.parse(result).message === 'User already registered. Please sign in.') {
                  seterrorMsg('Your email id is already registered. Kindly login.')
                }
                else if (JSON.parse(result).message === 'User account not activated.') {
                  setsentmail(true);
                  setSeconds(20);

                }
                else if (JSON.parse(result).message === 'Activation code sent to email.') {
                  setsentmail(true);
                  setSeconds(20);

                }

                else {
                  isDev && alert(JSON.parse(result).message);

                  // navigate("/registernlogin");
                }
              }
            }
            catch (e) {
              seterrorMsg('We were unable to register you. Kindly try again')
              setIsLoaing(false);
            }

          })
          .catch((error) => {
            setIsLoaing(false);

            seterrorMsg('We were unable to register you. Kindly try again');
          });
      }
      else {
        setIsLoaing(false);

        seterrorMsg('Error in creating session');
      }
    })
  };





  const register = () => {
    seterrorMsg(" ");
    if (!(password && password2 && email)) {
      seterrorMsg("Please enter all the fields");
      return;
    }
    if (hasUppercase && hasLowercase && hasDigit && hasSpecialChar && isLengthValid) {
      if (password === password2) {
        let regex = /\S+@\S+\.\S+/;
        if (regex.test(email)) {

          registerUser({
            user_name: email,
            email,
            password,
          });


        } else {
          seterrorMsg("Email is invalid");
        }
      } else {
        seterrorMsg("Password did not match.");
      }
    }
  };

  useEffect(() => {
    flag && loginUser(
      {
        email: email,
        password: password,
      },
      (val) => {
        if (val.user) {
          // console.log(val.user)
          dispatch(setUserAndId({ email: val.user.user.email, id: val.user.user.id }));
          navigate("/");
        } else {
          alert(val.message);
        }
      }
    );
    setEmail("");
    setPassword("");
    setPassword2("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag])


  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [seconds]);

  const resendActiveMail = () => {
    setIsLoaing(true);
    fetchToken((val) => {
      if (val.status === 'success') {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", localStorage.getItem("session-token"));
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
          credentials: "include",
        };
        fetch("/api/v1/resend_activation_link?email=" + email, requestOptions)
          .then((response) => {
            return response.text();
          })
          .then((result) => {

            try {
              var val = JSON.parse(result);
              if (val.status === 201) {
                setIsLoaing(false);
                setsentmail(true);
                setSeconds(20);
              }
              else {
                if (val.message) {
                  seterrorMsg(val.message);
                }
                else {
                  seterrorMsg('Error in sending mail. Try agin later');
                  // isDev && alert('Error in login');
                }
                setsentmail(false); setIsLoaing(false);

              }
            }
            catch (e) {
              seterrorMsg('Error in sending mail. Try agin later'); setsentmail(false); setIsLoaing(false);
            }
          })
          .catch((error) => { seterrorMsg('Error in sending mail. Try agin later'); console.log("error", error); setsentmail(false); setIsLoaing(false); });
      }
      else {
        alert('Error in creating session');
        setIsLoaing(false);

      }
    })

  }

  return (
    <main className="w-full flex justify-center items-center bg-[#f6f6f6]" style={{ padding: 20, height: '100%', minHeight: 600 }}>
      <Helmet>
        <title>Welcome To Appstorm.ai</title>
        <meta property="og:title" content='Welcome To Appstorm.ai'></meta>
        {/* <meta property="og:image" content={app?.app.app_metadata.thumbnail}></meta> */}
        <meta property="description" content='Type your idea as a prompt. Or click on the tornado icon to generate an idea'></meta>
        <meta property="og:description" content='Type your idea as a prompt. Or click on the tornado icon to generate an idea'></meta>
        <meta name="twitter:title" content='Welcome To Appstorm.ai' />
        <meta name="twitter:description" content='Type your idea as a prompt. Or click on the tornado icon to generate an idea' />
        {/* <meta name="twitter:image" content={app?.app.app_metadata.thumbnail} /> */}
      </Helmet>
      {
        isLoading ? <h1 className="text-xl" style={{ color: "black" }}>Loading...</h1> :
          <section className="bg-transparent flex flex-col " style={{ height: '100%', alignItems: 'center' }}>
            {sentmail ? (<div className="flex flex-col gap-4 w-[18rem]" style={{ flex: 1, justifyContent: 'center' }}>
              {firstTimeRegistration &&
                <Helmet>
                  <script>
                    {`
                    gtag('event', 'conversion', {'send_to': 'AW-850955653/rJ99CJ3U4N4YEIWb4pUD'});
                  `}
                  </script>
                </Helmet>
              }
              <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
              <h1 className="text-center text-3xl font-semibold text-black">
                Verify your email
              </h1>

              <div style={{ textAlign: "center", color: "black" }}>We have sent an email to</div>
              <div style={{ textAlign: "center", color: "black" }}>{email}</div>
              <div style={{ textAlign: "center", color: "black" }}>Click the activation link to get started.</div>
              <div style={{ textAlign: "center", color: "black", fontSize: 15 }}>{seconds > 0 ? ('Resend Link in ' + seconds) : (<span onClick={resendActiveMail} style={{ textAlign: "left", color: "black", fontSize: 15, textDecoration: 'underline', cursor: 'pointer' }}>Resend Mail</span>)}</div>

              <button
                onClick={() => { navigate("/password/" + btoa(email)); }}
                className="bg-[#10a37f] text-white p-3 rounded-sm"
              >
                Go to Login
              </button>
            </div>) : (<div className="flex flex-col gap-4 w-[18rem]" style={{ flex: 1, justifyContent: 'center' }}>
              <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
              <h1 className="text-center text-3xl font-semibold text-black">
                Create your account
              </h1>
              <div className="inputbox">
                <input
                  className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                  type="text"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
                <span>E-mail</span>
              </div>
              <div>
                <PasswordInput
                  value={password}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      register();
                    }
                  }}
                  setPassword={setPasswordCheck}
                  className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                  placeholder="Password"
                />
                {/* <div style={{ fontSize: 15, textAlign: 'end', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', opacity: password.length > 0 ? 1 : 0 }}><span style={{ marginRight: 5, color: strength == 'Weak' ? 'red' : 'green' }}>{strength}</span>< TbInfoSquareRoundedFilled data-tooltip-id="my-tooltip-click" style={{ fontSize: 15, color: '#056596' }} ></TbInfoSquareRoundedFilled></div> */}
                {/* <Tooltip
                  id="my-tooltip-click"
                  content="Hello world!"
                  openOnClick={true}
                  place='bottom'
                  opacity={1}
                  style={{ backgroundColor: "#dddddd", zIndex: 99, opacity: 1, borderRadius: 6, maxWidth: 350, color: 'black' }}
                  render={({ content, activeAnchor }) => (
                    <ul style={{ listStyle: 'disc', fontSize: 15, paddingLeft: 10 }}>
                      <li style={{ padding: '0px 12px' }}>At least one uppercase letter</li>
                      <li style={{ padding: '0px 12px' }}>At least one lowercase letter</li>
                      <li style={{ padding: '0px 12px' }}>At least one digit</li>
                      <li style={{ padding: '0px 12px' }}>At least one special character from the set @#$%^&+=_</li>
                      <li style={{ padding: '0px 12px' }}>Minimum length of 8 characters</li>
                    </ul>
                  )}
                /> */}
              </div>
              <PasswordInput
                value={password2}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    register();
                  }
                }}
                setPassword={setPassword2}
                className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                placeholder="Confirm Password"
              />

              {(!hasUppercase || !hasLowercase || !hasDigit || !hasSpecialChar || !isLengthValid) ? <div style={{ borderWidth: 1, borderColor: '#10a37f', padding: '10px 10px 10px 10px', }}>
                <h3>Your password must contain</h3>
                <ul style={{ listStyle: 'disc', fontSize: 15, padding: '0px 10px 0px 20px', fontWeight: '200', lineHeight: '25px' }}>
                  {!hasUppercase && <li style={{ padding: '0px 12px' }}>At least one uppercase letter</li>}
                  {!hasLowercase && <li style={{ padding: '0px 12px' }}>At least one lowercase letter</li>}
                  {!hasDigit && <li style={{ padding: '0px 12px' }}>At least one digit</li>}
                  {!hasSpecialChar && <li style={{ padding: '0px 12px' }}>At least one special character from the set @#$%^&+=_</li>}
                  {!isLengthValid && <li style={{ padding: '0px 12px' }}>Minimum length of 8 characters</li>}
                </ul>
              </div> : <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ textAlign: "left", color: "red", fontSize: 15, marginRight: 5 }}>{errorMsg} </div>
              </div>}

              <button
                onClick={register}
                className="bg-[#10a37f] text-white p-3 rounded-sm"
              >
                Submit
              </button>
              <button
                onClick={() => navigate("/registermobile")}
                className="border border-[#10a37f] text-black p-3 rounded-sm flex justify-center gap-2 items-center"
              >
                Sign up using mobile
              </button>
              <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
                <span className="text-black text-center">Already have an account?</span><button onClick={() => navigate('/login')} title="Go to Login Page" className="text-center" style={{ marginLeft: 10, color: '#10a37f' }}>Login</button>
              </div>
            </div>)

            }
            {/* <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px", maxWidth: 345 }}>
              <span className="text-center" style={{ color: "black", fontSize: 15 }}>
                By registering into Appstorm, you are agreeing to our <span onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</span> and <span onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</span>
              </span>
            </div> */}

            <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px" }}>
              <div onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</div>
              <div style={{ textAlign: "left", color: "black", fontSize: 15, margin: "0px 10px" }}>|</div>
              <div onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</div>
            </div>
          </section>
      }
    </main >
  );
};

export default Register;
