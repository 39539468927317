export const userChatHistory = (userDetails, callback) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("TOKEN", localStorage.getItem("session-token"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    credentials: "include",
  };

  fetch(
    "/api/v1/app_builder/apps/sessions/" + userDetails.id + "/latest",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      // console.log(result);
      try {
        var resp = JSON.parse(result);
        if (resp.data) {
          callback(resp);
        }
        else {
          if (window.twq) {
            window.twq('event', 'tw-omixx-omixz', {
              email_address: null // or you can pass a user's email address if available
            });
            // console.log(`Twitter tracking event: ${eventName}`);
          } else {
            // console.error('Twitter Pixel not initialized');
          }
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            credentials: "include",
          };
          fetch(
            "/api/v1/app_builder/apps/sessions/" + userDetails.id,
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              try {
                var session_details = JSON.parse(result);
                if (session_details?.data?.id) {
                  var requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                    credentials: "include",
                  };

                  fetch(
                    "/api/v1/app_builder/apps/sessions/" + userDetails.id + "/latest",
                    requestOptions
                  )
                    .then((response) => response.text())
                    .then((result) => {
                      // console.log(result);
                      try {
                        var resp = JSON.parse(result);
                        if (resp.data) {
                          callback(resp);
                        }
                        else {
                          callback(null)
                        }

                      }
                      catch (e) {
                        callback(null);
                      }
                    })
                    .catch((error) => console.log("error", error));
                }
                else {
                  callback(null);
                }
              }
              catch (e) {
                callback(null);
              }
            })
            .catch((error) => {
              callback(null)
            });

        }

      }
      catch (e) {
        callback(null);
      }
    })
    .catch((error) => console.log("error", error));
};
