import React, { useState, useEffect, useParams } from "react";
import { loginUserGoogle } from "../Conn/googleLogin";
import { useNavigate } from "react-router-dom";
import appLogo from "../Assets/appLogo.png";
import { FcGoogle } from "react-icons/fc";
import CookiePolicy from "../Components/CookiePolicy";
import '../App.css'
import { TailSpin } from "react-loader-spinner";
import { fetchToken } from "../Conn/session";
import Cookies from "js-cookie";
import { Helmet } from 'react-helmet';
import { useSDK } from "@metamask/sdk-react";
const Login = () => {
  const [seconds, setSeconds] = useState(0);
  const [email, setEmail] = useState("");
  const [errorMsg, seterrorMsg] = useState(" ");
  const [isLoading, setIsLoaing] = useState(false);
  const [isGoogly, setisGoogly] = useState(false);
  const [isMetamsk, setisMetamsk] = useState(false);
  // const isDev = process.env.NODE_ENV === 'development' ? true : false;
  const [cookieAccept, setcookieAccept] = useState(false);
  const navigate = useNavigate();

  const testEmail = (email) => {
    // let regex = /\S+@\S+\.\S+/;
    // return regex.test(email);
    const value = email;

    // Regular expression for detecting email addresses
    //eslint-disable-next-line
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

    // Regular expression for detecting phone numbers (simple example)
    //eslint-disable-next-line
    const phoneRegex = /\d{3}[-\.\s]?\d{3}[-\.\s]?\d{4}/;

    if (emailRegex.test(value)) {
      navigate("/password/" + btoa(email));
    } else if (phoneRegex.test(value)) {
      navigate("/mobile/" + btoa(email));
    } else {
      seterrorMsg("Please enter valid email or phone");
    }
  };

  const login = () => {
    if (!email) {
      seterrorMsg("Please enter valid email or phone");
      return;
    }
    testEmail(email);
  };

  const loginWithGoogle = () => {
    // if (!cookieAccept) {
    //   var consentCheck = document.getElementById('consentCheck')?.checked;
    //   if (consentCheck) {
    //     Cookies.set('_consent_appstorm_', true);

    //   }
    //   else {
    //     setshaking(true);
    //     setTimeout(() => {
    //       setshaking(false);
    //     }, 1000);

    //     return;
    //   }
    // }
    setisGoogly(true);
    fetchToken((val) => {
      if (val.status === 'success') {
        loginUserGoogle((resp) => {
          if (resp.uri) {
            setisGoogly(false);
            window.location.href = resp.uri;
          }
          else {
            setisGoogly(false);
            alert("Something went wrong. Try again")
          }
        })
      }
      else {
        alert('Error in creating session');
        setIsLoaing(false);

      }
    })

  }
  const setCookie = () => {
    Cookies.set('_consent_appstorm_', true, { expires: 365 });
    setcookieAccept(true);
    // var script = document.createElement('script');
    // script.async = true;
    // script.src = "https://www.googletagmanager.com/gtag/js?id=G-9PG529H5S0";
    // script.onload = function () {
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() { dataLayer.push(arguments); }
    //   gtag('js', new Date());
    //   gtag('config', 'G-9PG529H5S0');
    // };
    // document.head.appendChild(script);
  }
  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [seconds]);

  useEffect(() => {
    var isCookie = Cookies.get('_consent_appstorm_') === "true" ? true : false;
    setcookieAccept(isCookie);
  }, [])

  return (
    <main className="w-full flex justify-center items-center bg-[#f6f6f6]" style={{ flexDirection: 'column', padding: 20, height: '100%', minHeight: 550 }}>

      {cookieAccept &&
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-9PG529H5S0"></script>
          <script>
            {` 
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-9PG529H5S0');
              `}
          </script>
        </Helmet>
      }
      {!cookieAccept && <CookiePolicy setCookie={setCookie} />}

      <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
        {isLoading ? (
          <h1 className="text-xl" style={{ color: "black" }}>Loading...</h1>
        ) : (
          <section className="flex flex-col w-[18rem]">

            <div className="flex flex-col gap-4">
              <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
              <h1 className="text-center text-2xl font-semibold" style={{ color: "black" }}>
                Welcome back
              </h1>
              <div className="inputbox">
                <input
                  id="emailId"
                  className="border p-3 focus:outline-none w-full"
                  style={{ borderColor: '#10a37f' }}
                  type="text"
                  required
                  onChange={(e) => {
                    seterrorMsg('')
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
                <span>Email or Phone</span>
              </div>

              <div style={{ textAlign: "left", color: "red", fontSize: 15 }}>{errorMsg} </div>

              <button
                id='loginButtonId'
                onClick={login}
                className="bg-[#10a37f] text-white p-3 rounded-sm"
              >
                Continue
              </button>
              <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
                <span className="text-black text-center">Don't have an account? </span><button onClick={() => navigate('/register')} title="Go to Registration Page" className="text-center" style={{ marginLeft: 10, color: '#10a37f' }}>Sign Up</button>
              </div>
              <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                <div style={{ flex: 1, background: 'black', height: 1 }}></div>
                <div className="text-black text-center" style={{ margin: '0px 10px' }}>OR</div>
                <div style={{ flex: 1, background: 'black', height: 1 }}></div>
              </div>
              <button
                onClick={loginWithGoogle}
                className="border border-[#10a37f] text-black p-3 rounded-sm flex justify-center gap-2 items-center"
              >
                {!isGoogly ? (<> <FcGoogle />
                  Log in with Google</>) : (<TailSpin
                    height="20"
                    width="20"
                    color="#000000"
                    ariaLabel="tail-spin-loading"
                    radius="0.1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />)}


              </button>


            </div>

          </section>
        )
        }
      </div>
      {/* <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px",maxWidth:345 }}>
        <span className="text-center" style={{ color: "black",fontSize: 15 }}>
        By logging into Appstorm, you are agreeing to our <span onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</span> and <span onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</span>
        </span>
      </div> */}
      <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px" }}>
        <div onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</div>
        <div style={{ textAlign: "left", color: "black", fontSize: 15, margin: "0px 10px" }}>|</div>
        <div onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</div>
      </div>
    </main >
  );
};

export default Login;
