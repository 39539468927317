import React from 'react'
import { RiHeartFill, RiDeleteBin6Line, RiBookmarkLine, RiShareForwardLine, RiRefreshLine } from 'react-icons/ri';

function DummyAppScreen() {
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.8', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className={`flex justify-between gap-5 dark:bg-[#444654] bg-[#C8C8C8] `} style={{ paddingTop: 10, paddingBottom: 20, maxWidth: 800, width: '100%' }}>
                <div className="text-xl dark:text-[#fff] flex gap-1 like-button" style={{ marginLeft: 5 }}>
                    <button title="Like" className="dark:hover:text-white "><RiHeartFill /></button>{14}
                </div>
                <div style={{ display: 'flex', flexDirection: "row", marginRight: 10 }} className="text-xl dark:text-[#fff] flex gap-5">
                    <button title="Delete" className="dark:hover:text-white delete-button"><RiDeleteBin6Line /></button>
                    <button title='Bookmark' className="dark:hover:text-white hover:text-[#000] bookmark-button" ><RiBookmarkLine /></button>
                    <button
                        className="dark:text-white text-black text-xl flex flex-col justify-center items-center share-button"
                        title="Share"
                    >
                        <RiShareForwardLine></RiShareForwardLine>
                    </button>
                    <button
                        className="dark:text-white text-black text-xl flex flex-col justify-center items-center reload-button"
                        title="Reload"
                    >
                        <RiRefreshLine></RiRefreshLine>
                    </button>
                </div>
            </div>
        </div>

    )
}

export default DummyAppScreen