import React, { useEffect, useState } from 'react'
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../Conn/session";
import appLogo from "../Assets/appLogo.png";
import PasswordInput from "../Components/PasswordInput";

function ResetPass() {
    const [errorMsg, seterrorMsg] = useState(" ");
    const [successMsg, setsuccessMsg] = useState(false);

    const navigate = useNavigate();
    const [isLoading, setIsLoaing] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmpassword, setconfirmPassword] = useState("");
    const [params, setparams] = useState("");

    useEffect(() => {
        let uParams = window.location.search.split('&');
        if (uParams.length > 0) {
            uParams = uParams.find(val => val.indexOf("token=") !== -1);
            if (uParams && uParams.split("=")[1]) {
                setparams(uParams.split("=")[1]);
            }
            else {
                navigate('/');
            }

        }
        else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetPass = () => {

        // var iscode = params.find(val => val.indexOf("token=") !== -1);
        if (params) {
            if (!password) {
                seterrorMsg("Please enter new password");
                return;
            }
            if (!confirmpassword) {
                seterrorMsg("Please repeat password");
                return;
            }

            if (password !== confirmpassword) {
                seterrorMsg("Both passwords need to match");
                return;
            }

            seterrorMsg(" ");
            setsuccessMsg(false);
            setIsLoaing(true);
            fetchToken((val) => {
                if (val.status === 'success') {
                    var code = params;
                    var token = localStorage.getItem("session-token");
                    if (token && code) {
                        var formdata = new FormData();
                        formdata.append("password", password);
                        var myHeaders = new Headers();
                        myHeaders.append("Accept", "application/json");
                        myHeaders.append("TOKEN", token);
                        var requestOptions = {
                            method: "PUT",
                            headers: myHeaders,
                            redirect: "follow",
                            body: formdata,
                            credentials: "include",
                        };
                        fetch("/api/v1/users/forgot_password?token=" + code, requestOptions)
                            .then((response) => {
                                return response.text();
                            })
                            .then((result) => {
                                // console.log(result);
                                try {
                                    var val = JSON.parse(result);
                                    if (val.status === 200) {
                                        // localStorage.setItem("session-token", val.data.token);
                                        // localStorage.setItem("appstorm-user", val.data.user.id);
                                        // dispatch(
                                        //     setUserAndId({
                                        //         email: val.data.user.email,
                                        //         id: val.data.user.id,
                                        //     })
                                        // );
                                        setIsLoaing(false);
                                        setsuccessMsg(true);
                                        seterrorMsg("Password updated");
                                        // navigate("/");
                                    } else {
                                        seterrorMsg(val.message);
                                        setIsLoaing(false);
                                        // navigate("/");
                                    }
                                }
                                catch (e) {
                                    // console.log("error", e);
                                    alert("Link expired. Try again")
                                    setIsLoaing(false);
                                    // navigate("/");
                                }

                            })
                            .catch((error) => console.log("error", error));
                    }
                }
                else {
                    alert('Error in creating session');
                    setIsLoaing(false);
                    navigate("/");
                }
            })


        }
    }
    return (
        <div className="w-full flex justify-center items-center bg-[#f6f6f6]" style={{ padding: 20, height: '100%', minHeight: 550 }}>
            {isLoading ? <TailSpin
                height="40"
                width="40"
                color="#000000"
                ariaLabel="tail-spin-loading"
                radius="0.1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            /> : <div className="flex flex-col gap-4">
                <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
                <h1 className="text-center text-2xl font-semibold" style={{ color: "black" }}>
                    Welcome back
                </h1>

                <PasswordInput
                    id='passId'
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => {
                        seterrorMsg(" ");
                        setPassword(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            resetPass();
                        }
                    }}
                    value={password}
                    setPassword={setPassword}
                    placeholder="New Password"
                // disabled={successMsg}
                />
                <PasswordInput
                    id='passId'
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => {
                        seterrorMsg(" ");
                        setconfirmPassword(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            resetPass();
                        }
                    }}
                    value={confirmpassword}
                    setPassword={setconfirmPassword}
                    placeholder="Confirm Password"
                // disabled={successMsg}
                />
                {errorMsg && <div style={{ textAlign: "left", color: successMsg ? "green" : "red", fontSize: 15 }}>{errorMsg}</div>}

                {successMsg ? <button
                    id='loginButtonId'
                    onClick={() => {
                        navigate("/login");
                    }}
                    className="bg-[#10a37f] text-white p-3 rounded-sm"
                >
                    Go to login
                </button> : <button
                    id='loginButtonId'
                    onClick={resetPass}
                    className="bg-[#10a37f] text-white p-3 rounded-sm"
                >
                    Reset Password
                </button>}
            </div>
            }
        </div>
    )
}

export default ResetPass