export const loginUser = (logindata, callback) => {

  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("TOKEN", localStorage.getItem("session-token"));

  var formdata = new FormData();
  formdata.append("email", logindata.email);
  formdata.append("password", logindata.password);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    credentials: "include",
  };

  fetch("/api/v1/login", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      //console.log(result);
      try {
        if (
          JSON.parse(result) &&
          JSON.parse(result).status &&
          JSON.parse(result).status === "success"
        ) {
          callback({ user: JSON.parse(result).data });
          localStorage.setItem("session-token", JSON.parse(result).data.token);
          localStorage.setItem("appstorm-user", JSON.parse(result).data.user.id);
        } else {
          callback({ status: "Error", message: JSON.parse(result).message });
        }
      }
      catch (e) {
        callback({ status: 'error' });
      }
    })
    .catch((error) => { console.log("error", error); callback({ status: 'error', message: error }); });


};
