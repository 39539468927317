import React, { useEffect, useRef, useState } from "react";
import { removeApp, changeActionS } from "../features/HistorySlice";
//icons

import { useSelector, useDispatch } from "react-redux";
import { deleteApp } from "../Conn/deleteApp";
import { TailSpin } from "react-loader-spinner";
import AppComp from "./AppComp";
import ShareBox from "./ShareBox";



const ChatComp = ({ text, image, something = "something", isAnswer, isFullScreen, isNew = true, onSelectIdea }) => {
  const currChat = useRef(null);
  const tempList = useSelector((state) => state.history.tempList);
  // console.log(tempList, "templList")
  const [shouldDelete, setShouldDelete] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const isDev = process.env.NODE_ENV === 'development' ? true : false;
  const [share, setShare] = useState(false);
  const [SharedUrl, setSharedUrl] = useState(null);
  const userId = useSelector(state => state.auth.id);
  const parentRef = useRef(null);
  const dispatch = useDispatch();


  const removeAppList = () => {
    setdeleting(true);
    deleteApp(userId, currChat.current.id, (resp) => {
      setdeleting(false);
      setShouldDelete(false);

      // console.log(resp, "Deleted");
      if (resp.status === 'Success') {
        dispatch(removeApp({ id: currChat.current.id }));
      }
      else {
        isDev && alert(resp.message);
      }
    })

  }



  const shareApp = (val) => {
    var appData = { ...val };
    setShare(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", localStorage.getItem("session-token"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      credentials: "include",
      redirect: "follow",
    };

    fetch("/api/v1/apps/share/" + appData.id, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        // console.log(result);
        try {
          if (JSON.parse(result) && JSON.parse(result).status === 200) {
            appData.app_id = JSON.parse(result).data.id;
            setSharedUrl(appData);

            dispatch(changeActionS({ id: appData.id }));
          }
          else {
            setShare(false);
            isDev && alert(JSON.parse(result).message);
          }
        }
        catch (e) {
          setShare(false);
          isDev && alert(JSON.parse(result).message);
        }
      })

      .catch((error) => { setShare(false); console.log("error", error) });

  }



  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // setChildVisible(entry.isIntersecting);
        // console.log("show")
      },
      { root: null, rootMargin: '0px', threshold: 0 }
    );

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        // eslint-disable-next-line
        observer.unobserve(parentRef.current);
      }
    };
  }, []);


  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto", overflowX: "hidden" }} >
      {share && (
        <div style={{ width: "100%", height: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.8)', top: 0, left: 0, zIndex: 999, display: 'flex', justifyContent: "center", alignItems: 'center' }}>



          {SharedUrl ?
            <ShareBox setShare={setShare} app_id={SharedUrl?.app_id} app_name={SharedUrl?.app_name} />
            :
            (<TailSpin
              height="40"
              width="40"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="0.1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />)
          }
        </div>

      )}

      {shouldDelete && (
        <div style={{ width: "100%", height: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.8)', top: 0, left: 0, zIndex: 999, display: 'flex', justifyContent: "center", alignItems: 'center' }}>



          {!deleting ?
            <div className="relative z-[250] rounded-md bg-white dark:bg-[#202123] shadow-2xl " style={{ padding: '1rem' }}>
              <div>
                {currChat.current.app_metadata.shared ? <><p className="text-left font-bold mb-3 dark:text-white" style={{ paddingRight: ' 2rem' }}>{`Deleting ${currChat.current.app_name} will remove it for all shared users.`}</p><p className="text-left font-bold mb-3 dark:text-white">{`Confirm deletion?`}</p></>
                  : <p className="text-left font-bold mb-3 dark:text-white">{`Are you sure you want to delete ${currChat.current.app_name} ?`}</p>}
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                  <button
                    onClick={() => removeAppList()}
                    className="px-3 py-2 rounded-md bg-[#10A37F] text-white"
                    style={{ marginRight: 20 }}
                  >Yes

                  </button>
                  <button
                    onClick={() => {
                      setShouldDelete(false)
                    }}
                    className="px-3 py-2 rounded-md bg-[#10A37F] text-white"
                  >No

                  </button>
                </div>
              </div>
            </div>
            :
            (<TailSpin
              height="40"
              width="40"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="0.1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />)
          }
        </div>

      )}

      {tempList.map((val, index) => {
        return (
          <AppComp pos={index} onSelectIdea={onSelectIdea} visible={'visible' in val ? val.visible : true} key={index} shouldLoad={val.shouldLoad ? true : false} val={val} onDelete={(app) => {
            currChat.current = app; setShouldDelete(true)
          }
          } onShare={(val) => shareApp(val)} isFullScreen={isFullScreen} />
        )
      })}

    </div>
  );
};

export default ChatComp;
