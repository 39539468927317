export const loginUserGoogle = (callback) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", localStorage.getItem("session-token"));

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
    };

    fetch("/api/v1/social/google/login", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            // console.log(result);
            try {
                callback({ uri: JSON.parse(result).data.url });
            }
            catch (e) {
                callback({ "error": e })
            }

        })
        .catch((error) => console.log("error", error));
};
