import React, { useEffect, useState, memo, useRef } from "react";
import { MdPerson, MdOutlineDarkMode, MdLogout, MdNumbers, MdEmail, MdDownload } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { useSelector, useDispatch } from "react-redux";
import HistoryComp from "./HistoryComp";
import { deleteSpecificHistory, sortappList, resetAll, stopApps, setGPT4State } from "../features/HistorySlice";
import { deleteChat } from "../Conn/deleteChat";
import { logout } from "../features/authSlice";
import { Switch } from "@headlessui/react";
import { Oval } from 'react-loader-spinner';
import {
  setToogle,
} from "../features/HistorySlice";

import { userChatHistory } from "../Conn/userChatHistory";
//icons
import { BsImage, BsStars } from "react-icons/bs";
import { MdSearch, MdFilterList, MdLiveHelp } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { FiExternalLink } from 'react-icons/fi';
import SwitchComp from "./Switch";
import EmailSwitch from "./EmailSwitch";

//redux
import { searchList, setAppList } from "../features/HistorySlice";
//custom hooks
import useWindowDimensions from "../CustomHooks/useWindowHeightAndWidth";

// const todaysDate = new Date();

const Sidebar = ({ extraClass, neChatFun, newChat, changeChat, showonboarding, onLogOut }) => {
  const isDev = process.env.NODE_ENV === 'development' ? true : false;


  //request state
  const [requestForm, setRequestForm] = useState(false);
  //search bar
  const [searchText, setSearchText] = useState('')
  //menu
  const [menu, setMenu] = useState(false);
  const [useGPT4, setuseGPT4] = useState(localStorage.getItem('ai_gpt4') ? localStorage.getItem('ai_gpt4') === 'true' ? true : false : false)
  const [sortValue, setsortValue] = useState('All apps');
  const [oFilter, setoFilter] = useState(false);
  const oFilterRef = useRef(false);
  const filterButton = useRef(false);
  const filterOptions = useRef(false);
  const settingsButton = useRef(false);
  const settingsOptions = useRef(false);
  const searchInput = useRef(null);

  // Report as bug
  const [bugEnabled, setBugEnabled] = useState(false)
  const [isLoading, setisLoading] = useState(false);
  const [isRequesting, setisRequesting] = useState(false);
  const tempList = useSelector((state) => state.history.tempList);
  const user = useSelector(state => state.auth.user)
  const userId = useSelector(state => state.auth.id);

  const [displayMsg, setdisplayMsg] = useState(false);
  const dispatch = useDispatch();
  const [request, setRequest] = useState({
    title: "",
    desc: "",
    user_id: userId,
    file: ""
  })

  //window dimensions
  const { width } = useWindowDimensions();

  //delete history function
  const deleteHistory = (id, event) => {
    event.stopPropagation()
    deleteChat(id, () => {

      dispatch(deleteSpecificHistory({ id: id }))
    })
  }

  const installPWA = async () => {
    setMenu(false);
    if (window.deferredPrompt) {
      try {
        window.deferredPrompt.prompt(); // Call prompt() within the click handler
        const { outcome } = await window.deferredPrompt.userChoice;
        // console.log('User response to the install prompt: ' + outcome);
        window.deferredPrompt = null; // Reset deferredPrompt after use
      } catch (err) {
        console.error('Failed to prompt installation:', err);
      }
    } else {
      document.getElementById('pwapopup').style.display = 'flex';
    }
  }


  useEffect(() => {

    userChatHistory({
      id: userId
    }, (val) => {
      if (val && val.data) {
        if (val.data.user_app.length === 0) {
          showonboarding();
        }
        let sortedApps = sortList(val.data);

        let activeApp = [];
        let sharedApp = [];
        sortedApps.user_app.map((value, index) => {

          if (value.app_metadata && !value.app_metadata.shared && value.app_metadata.app_state) {

            activeApp.push(value.id);
            // sortedApps.user_app[index].app_metadata.app_state = false;

          }
          if (value.app_metadata && value.app_metadata.shared && !value.app_metadata.app_state) {

            sharedApp.push(value.id);

          }
        });
        dispatch(setAppList({
          appData: sortedApps
        }));
        if (activeApp.length > 0) {
          stopActiveApps(activeApp);
        }
        if (sharedApp.length > 0) {
          startSharedApps(sharedApp);
        }
      }
      else {
        showonboarding();
        dispatch(setAppList({
          appData: null
        }))
      }
    })


  }, []);

  const stopActiveApps = (activeApp) => {
    var token = localStorage.getItem("session-token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", token);
    var raw = JSON.stringify({
      "app_ids": activeApp
    });
    var requestOptions = {
      credentials: "include",
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("/api/v1/apps/" + userId + "/stop", requestOptions)
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        dispatch(stopApps({ ids: activeApp }));
        try {
          var resp = JSON.parse(result);
          isDev && console.log(resp);
        }
        catch (e) {
          // setisRequesting(false);
        }

      })
      .catch((error) => { console.log("error", error) });
  }

  const startSharedApps = (activeApp) => {
    var token = localStorage.getItem("session-token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", token);
    var raw = JSON.stringify({
      "app_ids": activeApp
    });
    var requestOptions = {
      credentials: "include",
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("/api/v1/apps/" + userId + "/start", requestOptions)
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        try {
          var resp = JSON.parse(result);
          isDev && console.log(resp);
        }
        catch (e) {
          // setisRequesting(false);
        }

      })
      .catch((error) => { console.log("error", error) });
  }
  // }, [toogleSidebar]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterButton.current && !filterButton.current.contains(event.target)) {
        if (oFilterRef.current && filterOptions.current && !filterOptions.current.contains(event.target)) {
          setoFilter(!oFilterRef.current);
          oFilterRef.current = false;
        }

      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [filterButton]);

  useEffect(() => {
    const handleClickOutsideSettings = (event) => {
      if (settingsButton.current && !settingsButton.current.contains(event.target)) {
        if (settingsOptions.current && !settingsOptions.current.contains(event.target)) {
          setMenu(false)
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutsideSettings);
  }, [settingsButton]);

  const sortappListt = (val) => {
    switch (val) {
      case 1: setsortValue('All apps')
        break;
      case 2: setsortValue('Shared apps')
        break;
      case 3: setsortValue('Bookmarked apps')
        break;
    }
    dispatch(sortappList({ value: val }));
    setoFilter(!oFilterRef.current);
    oFilterRef.current = false;

  }

  const sortList = (nameList) => {
    let appL = nameList.user_app;
    const sorted = [...appL].sort((a, b) => {
      const timeA = a.c_time;
      const timeB = b.c_time;
      return new Date(timeA) - new Date(timeB);
    });

    nameList.user_app = sorted.filter((val) => val.registered === true);
    return (nameList);
  };

  const onRequestSubmit = () => {
    if (request.title && request.desc && request.user_id) {
      setisRequesting(true);
      var formdata = new FormData();
      formdata.append("title", request.title);
      formdata.append("desc", request.desc);
      formdata.append("user_id", request.user_id);
      formdata.append("file", request.file);
      var token = localStorage.getItem("session-token");
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", token);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
        body: formdata,
      };
      fetch("/api/v1/raise_ticket/" + (bugEnabled ? 'BUG' : 'REQUEST'), requestOptions)
        .then((response) => {
          return response.text();
        })
        .then((result) => {
          try {
            var resp = JSON.parse(result);
            if (resp.status === 200) {
              setisRequesting(false);
              setRequestForm(false);
              setdisplayMsg(resp.message);
              setRequest({ ...request, title: "", desc: "", file: "" });
              setBugEnabled(false);
            }
            else {
              setisRequesting(false);
              setRequestForm(false);
              setdisplayMsg('Something went wrong. Try again.');
              setRequest({ ...request, title: "", desc: "", file: "" });
              setBugEnabled(false);
            }
          }
          catch (e) {
            setisRequesting(false);
            setRequestForm(false);
            setdisplayMsg('Something went wrong. Try again.');
            setRequest({ ...request, title: "", desc: "", file: "" });
            setBugEnabled(false);
          }

        })
        .catch((error) => {
          setisRequesting(false);
          setRequestForm(false);
          setdisplayMsg('Something went wrong. Try again.');
          setRequest({ ...request, title: "", desc: "", file: "" });
          setBugEnabled(false);
        });
    }
  }


  const onLogOff = () => {
    setisLoading(true);
    var token = localStorage.getItem("session-token");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", token);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    };
    fetch("/api/v1/logout", requestOptions)
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        // console.log(result);
        onLogOut();
        setisLoading(false);

        dispatch(
          logout()
        );
        dispatch(resetAll());
      })
      .catch((error) => console.log("error", error));

  }


  const onSearchApp = (curr) => {
    {
      if (curr.id) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  useEffect(() => {
    dispatch(searchList({
      value: searchText
    }))
  }, [searchText])

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });



  return (
    <>
      {displayMsg &&
        <div style={{ width: "100%", height: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.8)', top: 0, left: 0, zIndex: 999, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
          <div className="z-[250] rounded-md bg-white dark:bg-[#202123] shadow-2xl p-4" >
            <div>
              <p className="text-center font-bold mb-3 dark:text-white">{displayMsg}</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    setdisplayMsg(false);
                  }}
                  className="px-3 py-2 rounded-md bg-[#10A37F] text-white"
                >Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      }


      <div
        className={`bg-[#202123] p-1 h-full flex flex-col pt-3 px-3 ${extraClass} z-[100] sidebar-section`}
        style={{ position: "relative" }}
      >
        {/* <div id="pwapopup" className="w-full h-screen fixed top-0 left-0 z-[200] flex justify-center items-center dark:blur-back-dark blur-back-light" style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.8)', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', display: "none" }}>
          <div className="flex gap-2" style={{ padding: 20, flexDirection: 'column', maxWidth: 400, borderRadius: 10, marginTop: '5%', color: 'black', marginRight: 30, marginLeft: 30, backgroundColor: '#f6f6f6' }}>

            <h4 style={{ marginTop: 0, marginBottom: 10 }}>Tap the share button and “Add to Home Screen"</h4>
            <div className="flex justify-end" style={{ justifyContent: 'flex-end' }}>
              <button onClick={() => {
                document.getElementById('pwapopup').style.display = 'none'
              }} className="px-3 py-2 rounded-md text-white" style={{ fontSize: 13, backgroundColor: '#10A37F' }}>Close</button>
            </div>
          </div>
        </div> */}
        <div id="pwapopup" style={{ display: "none", backgroundColor: 'rgba(0,0,0,0.8)' }} className="w-full h-screen fixed top-0 left-0 z-[200] flex justify-center items-center">
          <div className="relative w-[20rem] h-auto rounded-lg flex flex-col items-center p-4 gap-4 shadow-xl" style={{ backgroundColor: '#f6f6f6' }}>

            <h4 style={{ marginTop: 0, marginBottom: 10 }}>Tap the share button and “Add to Home Screen"</h4>
            <div className="flex justify-end" style={{ justifyContent: 'flex-end' }}>
              <button onClick={() => {
                document.getElementById('pwapopup').style.display = 'none'
              }} className="px-3 py-2 rounded-md text-white" style={{ fontSize: 13, backgroundColor: '#10A37F' }}>Close</button>
            </div>
          </div>
        </div>
        {requestForm && (
          <div className="w-full h-screen fixed top-0 left-0 z-[200] flex justify-center items-center dark:blur-back-dark blur-back-light">
            <div className="relative w-[20rem] h-auto bg-white dark:bg-[#202123] rounded-lg flex flex-col items-center p-4 gap-4 shadow-xl">
              <button
                onClick={() => setRequestForm(false)}
                className="p-2 absolute right-2 dark:text-white active:bg-red-50 active:text-white top-2 rounded-lg"
              >
                <RxCross1></RxCross1>
              </button>
              <h2 className="text-2xl font-bold mb-4 dark:text-white">
                Request a Feature
              </h2>
              <p className="text-center w-[90%] dark:text-white">
                Let us know what feature that you would like to see us work on
                next. Why the feature is needed?
              </p>
              <div className="flex justify-between items-center text-white font-semibold">
                <p className="dark:text-white text-black">Report as bug</p>
                <Switch
                  checked={bugEnabled}
                  onClick={() => {
                    setBugEnabled(!bugEnabled)
                  }}
                  className={`${bugEnabled ? 'bg-[#10A37F]' : 'bg-[#D9D9E3]'}
          relative inline-flex h-[38px] scale-50 w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${bugEnabled ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
              <form className="w-[90%] text-start" onSubmit={() => { }}>
                <label
                  className="font-semibold mb-3 dark:text-white"
                  htmlFor="title"
                >
                  TITLE
                </label>
                <br />
                <input
                  className="mb-4 w-full focus:outline-none border rounded-md p-2 border-[#4D4D4F] bg-transparent text-[#2F2F2F] dark:text-white"
                  type="text"
                  placeholder="Short, descriptive title"
                  name="title"
                  value={request.title}
                  onChange={(e) => setRequest({ ...request, title: e.target.value })}
                />
                <br />
                <label
                  className="font-semibold mb-3 dark:text-white"
                  htmlFor="desc"
                >
                  DETAILS
                </label>
                <br />
                <textarea
                  placeholder="Any additional details..."
                  rows={4}
                  className="mb-4 w-full focus:outline-none border resize-none border-[#4D4D4F] rounded-md p-2 bg-transparent text-[#2F2F2F] dark:text-white"
                  type="text"
                  name="desc"
                  value={request.desc}
                  onChange={(e) => setRequest({ ...request, desc: e.target.value })}

                />
                <br />
                <input id="requestFile" type="file" style={{ display: 'none' }} multiple accept="image/*" onChange={async (e) => {
                  // console.log(e);
                  if (e.target.files && e.target.files[0]) {
                    // var base64 = await toBase64(e.target.files[0]);
                    setRequest({ ...request, file: e.target.files[0] })
                  }
                }} />
                <div className="flex justify-between items-center">
                  <button onClick={(event) => {
                    event.preventDefault();
                    document.getElementById("requestFile").click();
                  }} className="p-3 rounded-md bg-[#10A37F] text-white">
                    <BsImage></BsImage>
                  </button>
                  <button
                    className="px-3 py-2 w-[10rem] rounded-md bg-[#10A37F] text-white"
                    type="submit"
                    style={{ justifyContent: "center", display: 'flex' }}
                    onClick={(event) => {
                      event.preventDefault();
                      onRequestSubmit();
                    }}
                  >
                    {isRequesting ? <Oval
                      height={15}
                      width={15}
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel='oval-loading'
                      secondaryColor="#fff"
                      strokeWidth={5}
                      strokeWidthSecondary={5}

                    /> : bugEnabled ?
                      'Report bug' : 'Request a Feature'}

                  </button>
                </div>
                <h1></h1>
              </form>
            </div>
          </div>
        )}
        {width <= 820 &&
          <div
            className="cursor-pointer w-12 h-12 bg- z-40 border flex justify-center items-center dark:border-white border-black "
            style={{ position: "absolute", right: "-50px", top: 0, backgroundColor: "#202123" }}
            onClick={() => {
              dispatch(setToogle({ toogle: false }));
            }}
          >
            <RxCross1
              style={{ color: "white" }}
            />
          </div>
        }

        <div className=" rounded bg-[#202123] w-full text-start text-white text-sm flex gap-2 items-center mt-3 mb-3">

          <div className="rounded bg-transparent border border-[#4D4D4F] w-full flex items-center justify-center text-xl">
            <MdSearch></MdSearch>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="search"
              className="bg-transparent text-sm  py-1 px-3 focus:outline-none  w-[80%]"
              ref={searchInput}
            />
          </div>

          <div style={{ position: "relative" }}>
            <button className='filter-button' ref={filterButton} onClick={() => {
              oFilterRef.current = true;
              setoFilter(!oFilter);
            }}>
              <MdFilterList className="dark:hover:text-white hover:text-[#AAAAB5] text-xl"></MdFilterList>
            </button>
            {oFilter && (
              <div className="dropdown" ref={filterOptions}>
                <ul>
                  <li onClick={() => sortappListt(1)}>All apps</li>
                  <li onClick={() => sortappListt(2)}>Shared apps</li>
                  <li onClick={() => sortappListt(3)}>Bookmarked apps</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {sortValue !== 'All apps' && <div style={{ display: 'flex', marginBottom: 10 }}><div className="tags gap-2"><div>{sortValue}</div><RxCross1
          onClick={() => { setsortValue('All apps'); dispatch(sortappList({ value: 1 })); }}
          style={{ color: "white", cursor: "pointer" }}
        /></div></div>}

        <div className="flex-1 w-full bg-transparent flex flex-col gap-2 overflow-auto">
          {/* all history here */}
          <div className="w-full h-auto overflow-autoS flex-1">
            {/* {historyData?.filter(onSearch).reverse().map((curr, index) => {

            return (
              <HistoryComp changeChat={changeChat} deleteFun={deleteHistory} key={index} initialText={curr.chat_session_metadata ? curr.chat_session_metadata.session_name : curr.id} id={curr.id} />

            );

          })} */}
            {tempList?.filter(onSearchApp).reverse().map((curr, index) => {

              return (
                <HistoryComp visible={'visible' in curr ? curr.visible : true} deleted={'deleted' in curr ? curr.deleted : false} changeChat={changeChat} deleteFun={deleteHistory} key={index} initialText={curr.app_name} id={curr.id} />

              );

            })}

          </div>
        </div>


        {/* </TabPanel> */}
        {/* <TabPanel>
          <div className="flex-1 w-full bg-transparent flex flex-col gap-2 overflow-auto">
         
            <div className="w-full h-auto overflow-autoS flex-1">
              {historyData?.filter(onSearch).reverse().map((curr, index) => {

                return (
                  <HistoryComp deleteFun={deleteHistory} key={index} initialText={curr.chat_session_metadata ? curr.chat_session_metadata.session_name : curr.id} id={curr.id} />

                );

              })}

            </div>
          </div>

          <div className=" rounded bg-[#202123] w-full text-start text-white text-sm flex gap-2 items-center mt-3 mb-3">
            <div className="rounded bg-transparent border border-[#4D4D4F] w-full flex items-center justify-center text-xl">
              <MdSearch></MdSearch>
              <input
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="search"
                className="bg-transparent text-sm  py-1 px-3 focus:outline-none  w-[80%]"
              />
            </div>
          </div>
        </TabPanel>
      </Tabs> */}

        <hr className="border-[#4D4D4F] mb-2" />

        {/* <button
        className="p-3 rounded hover:bg-[#353740] w-full text-start text-white text-sm flex gap-2 items-center"
        onClick={() => {
          dispatch(clearHistory());
        }}
      >
        <RiDeleteBin6Line />
        Clear apps
      </button>

      <button
        onClick={() => setRequestForm(true)}
        className="p-3 rounded hover:bg-[#353740] w-full text-start text-white text-sm flex gap-2 items-center justify-between"
      >
        Request a Feature
      </button>
      <button className="p-3 rounded w-full text-white text-sm flex items-center justify-between">
        <p>Dark Mode</p>
        <SwitchComp></SwitchComp>
      </button> */}
        <div
          className="p-3 flex rounded overflow-ellipsis hover:bg-[#353740] w-full text-start text-white text-sm flex gap-2 items-center justify-between relative"
        >
          <div className="flex flex-1 overflow-hidden line-clamp-1 gap-2 overflow-ellipsis">
            <span className="text-xl"><MdPerson /></span>
            <p className="flex-1 overflow-ellipsis overflow-hidden line-clamp-1">{user}</p>
            <button ref={settingsButton} onClick={() => setMenu(!menu)} title="Menu" className="text-xl z-[100]"> <BsThreeDots /></button>
          </div>

          <div ref={settingsOptions} className={`absolute w-full h-auto rounded-lg transition-all duration-300 z-[99] bg-black right-1 ${menu ? 'opacity-100 bottom-12' : 'opacity-0 -bottom-32'}`} style={{ display: menu ? 'block' : 'none' }}>



            <hr className="border-[#343541]" />

            <button title="Tutorial" onClick={() => { showonboarding(); setMenu(false); }} className="px-6 py-4 w-full flex items-center gap-2">
              <MdLiveHelp />
              <p>Take a tour</p>
            </button>
            <hr className="border-[#343541]" />

            <button title="Or report a bug" onClick={() => { setMenu(false); setRequestForm(true); }} className="px-6 py-4 w-full flex items-center gap-2">
              <FiExternalLink />
              <p>Request a Feature</p>
            </button>

            {/* <hr className="border-[#343541]" />
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
              <button title="Enable or disable dark mode" className="px-6 py-4 w-full flex items-center gap-2">
                <MdOutlinePrivacyTip />
                Privacy policy
              </button>
            </div> */}

            <hr className="border-[#343541]" />
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
              <button title="Enable or disable dark mode" className="px-6 py-4 w-full flex items-center gap-2">
                <MdOutlineDarkMode />
                Dark Mode
              </button>
              <SwitchComp></SwitchComp>
            </div>
            <hr className="border-[#343541]" />
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
              <button title="Enable or disable dark mode" className="px-6 py-4 w-full flex items-center gap-2" style={{ textAlign: 'left' }}>
                <MdEmail />
                Email notifications
              </button>
              <EmailSwitch></EmailSwitch>
            </div>

            <hr className="border-[#343541]" />
            <button disabled title="version" className="px-6 py-4 w-full flex items-center gap-2">
              <MdNumbers />
              <p>Version 1.7.0</p>
            </button>
            <hr className="border-[#343541]" />
            <button onClick={installPWA} title="version" className="px-6 py-4 w-full flex items-center gap-2">
              <MdDownload />
              <p>Install Appstorm</p>
            </button>
            <hr className="border-[#343541]" />
            <button style={{ alignItems: "center", justifyContent: "space-between", flexDirection: "row" }} title="log-out" onClick={onLogOff} className="px-6 py-4 w-full flex items-center gap-2 hover:rounded-b-lg">
              <div style={{ alignItems: "center", flexDirection: "row" }} className="flex gap-2">
                <MdLogout />
                <p>Log out</p>
              </div>
              {isLoading && <Oval
                height={15}
                width={15}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#fff"
                strokeWidth={5}
                strokeWidthSecondary={5}

              />}
            </button>


          </div>
        </div>

      </div>
    </>
  );
};

export default memo(Sidebar);
