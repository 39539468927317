import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import Sidebar from "./Sidebar";
import ChatComp from "./ChatComp";
import ToolTip from "./ToolTip";
import DummyAppScreen from "./DummyAppScreen";
import FullScreenApp from "./FullScreenApp";
import DummyScreenMagicLamp from "./DummyScreenMagicLamp";
import Gallery from "./Gallery";
import appLogo from "../Assets/favicon-appstorm.png";
import appLogoWithName from '../Assets/appLogo_wo_icon.png'
import { TailSpin } from "react-loader-spinner";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import "../App.css";
import Newsletter from "./Newsletter";
//redux and its functions
import { useDispatch, useSelector } from "react-redux";
import {
  setToogle,
  changeCurrChatFeed,
  addCurrApp,
  setEmailNotification
} from "../features/HistorySlice";
// import Cookies from "js-cookie";
import Web3 from 'web3';
// import detectEthereumProvider from '@metamask/detect-provider';
// import { useSDK } from "@metamask/sdk-react";
//icons
import { FiMenu } from "react-icons/fi";
import { BsSun } from "react-icons/bs";
import { GiMagicLamp } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiSendPlane2Fill } from "react-icons/ri";
//custom hooks
import useWindowDimensions from "../CustomHooks/useWindowHeightAndWidth";
// import MetamaskPay from "./MetamaskPay";

const HomePage = () => {
  //States
  // const { sdk, connected, connecting, provider, chainId } = useSDK();

  const steps = [
    {
      selector: '.prompt-area',
      position: 'center',
      content: () => (<ToolTip title='Welcome to Appstorm!' description="Let's walk you through our main attractions. It's quick, we promise." />),
      action: () => {
        if (width >= 820) {
          dispatch(setToogle({ toogle: true }));
        }
        if (width < 820) {
          dispatch(setToogle({ toogle: false }));
        }
        // setshowDummy2(false);
      }
    },
    {
      selector: '.magic-button',
      content: () => (<ToolTip title='Instant App Magic' description='Just a click on our enchanted lamp, and voila! An app is automatically crafted for you.' />),
      action: () => {
        const cube = document.getElementById("image");
        cube.classList.remove("rotated-image");
        // if (toogleSidebar) {
        //   setshowDummy2(true);
        // }

      }
    },
    {
      selector: '.prompt-area',
      content: () => (<ToolTip title='Create an app' description='Got an app idea? Write it down and hit the send icon to bring it to life' />),
      action: () => {
        // setshowDummy2(false);
      }
    },
    {
      selector: '.appstorm-logo',
      content: () => (<ToolTip title='Generate ideas' description='Tired of being sensible? Tap the Appstorm logo for a whirlwind of wild ideas.' />),
      action: () => {
        const cube = document.getElementById("image");
        cube.classList.add("rotated-image");
        setInput('');
        // setshowDummy2(false);
      }
    },
    {
      selector: '.app-bar',
      content: () => (<ToolTip title='Generate custom ideas' description='Got a special topic? Punch it in, hit the Appstorm logo and let the idea shower begin.' />),
      action: () => {
        const cube = document.getElementById("image");
        cube.classList.remove("rotated-image");
        setshowDummy(false);
        setInput('Paintings');
      }
    },
    {
      selector: '.bookmark-button',
      content: () => (<ToolTip title='Bookmark an App' description='Found an app worth keeping? Tuck it safely in your digital treasure chest.' />),
      action: () => {
        setshowDummy(true);
        setInput('');
      }
    },
    {
      selector: '.share-button',
      content: () => (<ToolTip title='Share an App' description='Struck app gold? Time to spread the joy with your favorite people.' />),
      action: () => {
        setshowDummy(true);
      }
    },
    {
      selector: '.delete-button',
      content: () => (<ToolTip title='Delete an app' description='An app not quite your cup of tea? Banish it with a click and reclaim your space.' />),
      action: () => {
        setshowDummy(true);
      }
    },
    {
      selector: '.reload-button',
      content: () => (<ToolTip title='Reload an app' description='Gremlins lazing around? Give them a poke with a quick reload.' />),
      action: () => {
        setshowDummy(true);
      }
    },
    {
      selector: '.like-button',
      content: () => (<ToolTip title='See your app likes' description='Checkout how much people love your creation.' />),
      action: () => {
        setshowDummy(true);
      }
    },
    {
      selector: '.sidebar-section',
      content: () => (<ToolTip title='App sidebar' description='Tap the sidebar for a trip down memory lane, filled with your app masterpieces and...well, the others.' />),
      action: () => {
        setshowDummy(false);

        if (width < 820) {
          dispatch(setToogle({ toogle: true }));
        }
      }
    },
    {
      selector: '.filter-button',
      content: () => (<ToolTip title='Filter apps' description="Easily find your apps by selecting 'bookmarked' or 'shared'." />),
    },
    // {
    //   target: '.prompt-area',
    //   content: <div><h1><strong>Bookmark an App</strong></h1> <br></br> <h4>Found an app worth keeping? Tuck it safely in your digital treasure chest.</h4></div>,
    // }
  ];
  const isDev = process.env.NODE_ENV === 'development' ? true : false;
  // const isDev = true;
  const userId = useSelector(state => state.auth.id);
  const chatFeedValue = useRef();
  const chatRef = useRef();
  const ideasPool = useRef();
  const customideasPool = useRef();
  const currRequest = useRef(null);
  const [chatFeed, setChatFeed] = useState([]); // for answer
  const [newsletter, setnewsletter] = useState(false);
  // const [metamaskPay, setmetamaskPay] = useState(false);

  const [promptLength, setpromptLength] = useState(0);
  const [ideas, setideas] = useState([{ idea: "A GPT that analyze CSV data and provide a professional report" },
  { idea: "A GPT can generates images of mythological creatures crossed with cyberpunk" },
  { idea: "A GPT that can read the latest news and write it as a song" },
  { idea: "A home fixer GPT that can analyze images and suggest repairs" },
  { idea: "A GPT that only responds in famous quotes" },
  { idea: "An app that can generate personalized travel recommendations with custom descriptions and images." }]); // for ideas
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const ongoing = useRef(false);
  const [oFilter, setoFilter] = useState(false);
  const oFilterRef = useRef(false);

  // const [chatConnected, setchatConnected] = useState(true);
  // const [session, setsession] = useState(null);
  const [newSession, setnewSession] = useState(true);
  const isFocusedBack = useRef(false);
  const [currAppName, setcurrAppName] = useState(null);
  // const [currChatName, setCurrChatName] = useState("");
  // const [toogleSidebar, setToogleSidebar] = useState(true)
  const darkMode = useSelector((state) => state.history.darkModeState);
  const socket = useRef(null);
  // dispatching functions to global state
  const dispatch = useDispatch();
  const currChat = useSelector((state) => state.history.currChat);
  const toogleSidebar = useSelector((state) => state.history.toogleSidebar);
  const sessionData = useSelector((state) => state.history.sessionData);
  const appList = useSelector((state) => state.history.appList);
  const refresh = useSelector((state) => state.history.refresh);
  const filterButton = useRef(false);
  const filterOptions = useRef(false);
  const [isActive, setIsActive] = useState(false);
  const [seconds, setSeconds] = useState(0);
  // const [deviceType, setDeviceType] = useState("");

  const showtooltip = localStorage.getItem('set-tooltip') == 'true' ? true : false;
  //window height and width
  const { width } = useWindowDimensions();

  const [headerVisible, setHeaderVisible] = useState(true);
  // const [loadingTexts, setloadingTexts] = useState(null);
  // const loaderTexts = [];
  // const currentloader = useRef(loaderTexts);

  // useEffect(() => {
  //   if (!metamaskPay) {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Accept", "application/json");
  //     myHeaders.append("TOKEN", localStorage.getItem("session-token"));

  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //       credentials: "include",
  //     };
  //     fetch("/api/v1/users/settings/" + userId, requestOptions)
  //       .then((response) => response.text())
  //       .then((result) => {
  //         isDev && console.log("Fetched user settings");
  //         try {
  //           var jResult = JSON.parse(result);
  //           if (jResult.data && jResult.data.configuration) {
  //             // setnewsletter(true);
  //             sethasuserSetting(true);
  //             if ("enable_email_notification" in jResult.data.configuration) {
  //               isDev && console.log("User has setting enable_email_notification");
  //               dispatch(setEmailNotification({
  //                 value: jResult.data.configuration.enable_email_notification
  //               }))
  //             }
  //             else {
  //               setnewsletter(true);
  //             }
  //           }
  //           else {
  //             sethasuserSetting(false);
  //             isDev && console.log("User does not have settings");
  //             setnewsletter(true);
  //           }

  //         }
  //         catch (e) {
  //           isDev && console.log("Failed to fetch user settings", e);
  //         }

  //       })
  //       .catch((error) => { console.log("error", error) });
  //   }
  // }, [metamaskPay])



  useEffect(() => {

    if (scrollRef.current) {
      scrollRef.current.scroll({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
      // }
    }
  }, [refresh]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterButton.current && !filterButton.current.contains(event.target)) {
        if (oFilterRef.current && !filterOptions.current.contains(event.target)) {
          setoFilter(!oFilterRef.current);
          oFilterRef.current = false;
        }

      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [filterButton]);
  const generateResponse = () => {
    if (!isLoading) {
      let parsedCommandU = input;
      var punctuation = /[\.,?!]/g;
      parsedCommandU = parsedCommandU.replace(punctuation, " ");
      parsedCommandU = parsedCommandU.replace('\n', " ");
      parsedCommandU = parsedCommandU.trim();
      if (!parsedCommandU) {
        return;
      }
      if (['hi', 'hello', 'help'].indexOf(parsedCommandU.toLowerCase()) !== -1) {
        setInput(parsedCommandU);
        clickclick(null, parsedCommandU);
        return;
      }
      setIsLoading(true);
      dispatch(addCurrApp({
        app: {
          role: "user",
          prompt: parsedCommandU,
        },
        new: true
      }
      ));
      if (sessionData && sessionData.id) {

        // if (socket.current) {
        //   handleStart();
        //   logMessage('Info', 'Prompt sent for ' + socket.current.id);
        //   socket.current.emit("message", parsedCommandU);
        // }
        // else {
        resumeChat(parsedCommandU);
        // }
      }
      else {
        newChat(parsedCommandU);
      }

      setInput("");
      setpromptLength(0);
    }
  };

  useEffect(() => {
    let interval = null;

    if (isActive) {
      if (seconds < 150) {
        interval = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds + 1);
          isDev && console.log(seconds);
        }, 1000);
      }
      // if(seconds === 30)
      // {
      //   changeText
      // }
      else {
        setIsLoading(false);
        setSeconds(0);
        setIsActive(false);
        clearInterval(interval);
        clearTimeout();
        dispatch(addCurrApp({
          app: {
            error: 'Request timed out. Try again',
          }
        }
        ));
        ongoing.current = false;
        // socket.current.disconnect();
        // socket.current = null;
        currRequest.current = null;
        // socket.current.destroy();
        // socket.current = null;


      }
    } else {
      setSeconds(0);
      clearInterval(interval);

    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, seconds]);

  const handleStop = () => {
    setIsActive(false);
    setSeconds(0);
  };
  const handleStart = (value) => {
    setIsActive(true);
    setSeconds(value);
  };





  const scrollToBottom = () => {
    if (appList && appList.length > 0 && appList[appList.length - 1].id) {
      const element = document.getElementById('app' + (appList[appList.length - 1].id));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    else {
      if (scrollRef.current) {
        scrollRef.current.scroll({
          top: scrollRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }

  };
  const clickclick = async (evt, parsedCommandU) => {

    // if (!isLoading) {
    closeToolTip()
    const cube = document.getElementById("image");
    cube.classList.add("rotated-image");
    displayRandomItemWithoutRepetition(parsedCommandU);
    // }
  };

  const displayRandomItemWithoutRepetition = (parsedCommandU) => {
    // Check if all items have been displayed
    var token = localStorage.getItem("session-token");
    const cube = document.getElementById("image");
    if (input && !isLoading && ['hi', 'hello', 'help'].indexOf(parsedCommandU ? parsedCommandU.toLowerCase() : input.toLowerCase()) === -1) {
      var items = customideasPool.current;
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", token);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };
      fetch("/api/v1/app_builder/ideas?seed=" + input, requestOptions)
        .then((response) => {
          return response.text();
        })
        .then((result) => {
          try {
            var val = JSON.parse(result);
            if (val.status === 200) {

              if (val.data.ideas.length > 0) {

                customideasPool.current = val.data.ideas;
              }
              items = customideasPool.current;

              setTimeout(() => {
                // setInput('');
                cube.classList.remove("rotated-image");

                var ide = items.splice(0, 6);
                dispatch(addCurrApp({
                  ideas: {
                    ideas: ide,
                    for: "Ideas for " + input
                  }
                }
                ));

                customideasPool.current = items;
              }, 1000);

            }
          }
          catch (e) {
            setTimeout(() => {
              cube.classList.remove("rotated-image");

            }, 1000);

          }
        })
        .catch((error) => console.log("error", error));
    }
    else {
      getIdeasPool(parsedCommandU);
    }
  }

  const getIdeasPool = (parsedCommandU) => {
    var token = localStorage.getItem("session-token");
    const cube = document.getElementById("image");
    var items = ideasPool.current;
    if (items.length === 0) {

      if (token) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", token);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
          credentials: "include",
        };
        fetch("/api/v1/app_builder/ideas?sample=100", requestOptions)
          .then((response) => {
            return response.text();
          })
          .then((result) => {
            // console.log(result);
            var val = JSON.parse(result);
            if (val.status === 200) {

              if (val.data.ideas.length > 0) {
                ideasPool.current = val.data.ideas;
              }
              items = ideasPool.current;
              const randomIndex = Math.floor(Math.random() * items.length);

              // Get the item at the random index
              const randomItem = items[randomIndex];

              setTimeout(() => {
                cube.classList.remove("rotated-image");
                if (isLoading) {
                  items.splice(randomIndex, 1);
                  setInput(randomItem.idea);
                }
                else {
                  var ide = items.splice(0, 6);
                  dispatch(addCurrApp({
                    ideas: {
                      ideas: ide,
                      for: "Ideas"
                    }
                  }
                  ));
                }

                ideasPool.current = items;
              }, 1000);

            }
          })
          .catch((error) => console.log("error", error));
      }
    }

    else {
      // Get a random index from the array
      const randomIndex = Math.floor(Math.random() * items.length);

      // Get the item at the random index
      const randomItem = items[randomIndex];

      // Remove the displayed item from the array
      // items.splice(randomIndex, 1);

      // console.log(ideasPool.current);

      // Display the item
      setTimeout(() => {
        cube.classList.remove("rotated-image");
        if (isLoading) {
          items.splice(randomIndex, 1);
          setInput(randomItem.idea);
        }
        else {
          var ide = items.splice(0, 6);
          dispatch(addCurrApp({
            ideas: {
              ideas: ide,
              for: "Ideas"
            }
          }
          ));
        }

        ideasPool.current = items;
        // setInput(randomItem.idea);
      }, 1000);

    }
  }


  const getMagicCommand = () => {
    var token = localStorage.getItem("session-token");
    const cube = document.getElementById("magic-lamp");
    var items = ideasPool.current;
    if (items.length === 0) {

      if (token) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", token);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
          credentials: "include",
        };
        fetch("/api/v1/app_builder/ideas?sample=100", requestOptions)
          .then((response) => {
            return response.text();
          })
          .then((result) => {
            // console.log(result);
            var val = JSON.parse(result);
            if (val.status === 200) {

              if (val.data.ideas.length > 0) {
                ideasPool.current = val.data.ideas;
              }
              items = ideasPool.current;
              const randomIndex = Math.floor(Math.random() * items.length);

              // Get the item at the random index
              const randomItem = items[randomIndex];

              setTimeout(() => {
                cube.classList.remove("icon-shake");
                items.splice(randomIndex, 1);
                setIsLoading(true);
                dispatch(addCurrApp({
                  app: {
                    role: "user",
                    prompt: randomItem.idea,
                  },
                  new: true
                }
                ));
                if (sessionData && sessionData.id) {

                  // if (socket.current) {
                  //   // if (chatFeed.length > 0) {
                  //   handleStart();
                  //   socket.current.emit("message", randomItem.idea);
                  //   // }
                  // }
                  // else {
                  resumeChat(randomItem.idea);
                  // }
                }
                else {
                  newChat(randomItem.idea);
                }
                ideasPool.current = items;
              }, 1000);

            }
          })
          .catch((error) => console.log("error", error));
      }
    }

    else {
      // Get a random index from the array
      const randomIndex = Math.floor(Math.random() * items.length);

      // Get the item at the random index
      const randomItem = items[randomIndex];

      // Remove the displayed item from the array
      // items.splice(randomIndex, 1);

      // console.log(ideasPool.current);

      // Display the item
      setTimeout(() => {
        cube.classList.remove("icon-shake");
        items.splice(randomIndex, 1);
        setIsLoading(true);
        dispatch(addCurrApp({
          app: {
            role: "user",
            prompt: randomItem.idea,
          },
          new: true
        }
        ));
        if (sessionData && sessionData.id) {

          // if (socket.current) {
          //   // if (chatFeed.length > 0) {
          //   handleStart();
          //   socket.current.emit("message", randomItem.idea);
          //   // }
          // }
          // else {
          resumeChat(randomItem.idea);
          // }
        }
        else {
          newChat(randomItem.idea);
        }

        ideasPool.current = items;
        isDev && console.log(ideasPool.current.length, randomItem.idea)
      }, 1000);

    }
  }

  //scroll is after-math of all
  useEffect(() => {

    if (chatFeed && chatFeed.length > 0) {
      // console.log(chatFeed);
      if (currChat) {
        dispatch(changeCurrChatFeed({ id: currChat.id, conversation: chatFeed }));
      }
      // if (scrollRef.current) {
      //   scrollRef.current.scroll({
      //     top: scrollRef.current.scrollHeight,
      //     behavior: "smooth",
      //   });
      // }
    }
    else {
      setcurrAppName(null);
    }
  }, [chatFeed]);

  useEffect(() => {
    if (chatRef && chatRef.current) {
      chatRef.current.style.height = "auto";
      chatRef.current.style.height = chatRef.current.scrollHeight + 'px';
    }

    if (input.length === 0) {
      document.getElementById('sendbuttonId').style.background = 'none';
    }
    else {
      document.getElementById('sendbuttonId').style.background = '#707079';

    }
  }, [input]);

  // listening to change in currChat
  useEffect(() => {
    if (currChat) {
      setnewSession(false);
      // console.log("Curr chat is changed to ", currChat.id);
      setcurrAppName(null);

      if (currChat.conversations) {
        chatFeedValue.current = currChat.conversations;
        if (currChat.chat_session_metadata) {
          setcurrAppName(currChat.chat_session_metadata.session_name);
        }
        else {
          for (let i = 0; i < currChat.conversations.length; i++) {
            if (currChat.conversations[i].app) {
              setcurrAppName(currChat.conversations[i].app.app_name);
              break;
            }
          }
        }
      }
      let newArray = [...chatFeedValue.current];
      setChatFeed(
        [...newArray]
      );
    }
    else {
      setChatFeed([]);

    }
  }, [currChat]);



  useEffect(() => {
    if (width >= 820) {
      dispatch(setToogle({ toogle: true }));
    }
    if (width < 820) {
      dispatch(setToogle({ toogle: false }));
    }
    if (chatRef && chatRef.current) {
      chatRef.current.style.height = "auto";
      chatRef.current.style.height = chatRef.current.scrollHeight + 'px';
    }

  }, [width, dispatch]);

  //switch dark mode
  useLayoutEffect(() => {
    let bodyClass = window.document.body.classList;
    darkMode === "dark" ? bodyClass.add("dark") : bodyClass.remove("dark");
  }, [darkMode]);

  const getExamples = () => {
    ideasPool.current = ideas;
    var token = localStorage.getItem("session-token");
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", token);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };
      fetch("/api/v1/app_builder/ideas?sample=100", requestOptions)
        .then((response) => {
          return response.text();
        })
        .then((result) => {
          // console.log(result);
          var val = JSON.parse(result);
          if (val.status === 200) {
            if (val.data.ideas.length > 0) {
              setideas(val.data.ideas);
              ideasPool.current = [...val.data.ideas];
            }
          }
        })
        .catch((error) => console.log("error", error));
    }
    else {
      // fetchToken();
      // setcheckingLog(false);
    }
  }




  const closeToolTip = () => {
    const elements = document.querySelectorAll('.tooltip')
    elements.forEach(ele => {
      ele.style.display = 'none'
    })
    localStorage.setItem('set-tooltip', true);
  }
  const [hasuserSetting, sethasuserSetting] = useState(false)
  useEffect(() => {

    // setmetamaskPay(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("TOKEN", localStorage.getItem("session-token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    };
    fetch("/api/v1/users/settings/" + userId, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        isDev && console.log("Fetched user settings");
        try {
          var jResult = JSON.parse(result);
          if (jResult.data && jResult.data.configuration) {
            // setnewsletter(true);
            sethasuserSetting(true);
            if ("enable_email_notification" in jResult.data.configuration) {
              isDev && console.log("User has setting enable_email_notification");
              dispatch(setEmailNotification({
                value: jResult.data.configuration.enable_email_notification
              }))
            }
            else {
              setnewsletter(true);
            }
          }
          else {
            sethasuserSetting(false);
            isDev && console.log("User does not have settings");
            setnewsletter(true);
          }

        }
        catch (e) {
          isDev && console.log("Failed to fetch user settings", e);
        }

      })
      .catch((error) => { console.log("error", error) });


    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    getExamples();

    chatFeedValue.current = [];
    //add event listener to scroll button
    let obj1 = scrollRef.current;
    let scrollToBottomButton = scrollToBottomButtonRef.current;
    width > 768 && (scrollToBottomButton.style.display = "none");
    if (obj1) {
      obj1.addEventListener("scroll", () => {
        if (obj1.scrollTop !== obj1.scrollHeight - obj1.offsetHeight) {
          scrollToBottomButton.style.display = "block";
        } else {
          scrollToBottomButton.style.display = "none";
        }
      });
    }

    setInterval(checkFocus, 200);

    function checkFocus() {


      if (document.hasFocus()) {
        if (isFocusedBack.current) {
          isDev && console.log("Focuesed back");
          if (socket.current === null) {
          }
          isFocusedBack.current = false;
        }
      } else {
        isFocusedBack.current = true;
      }
    }


    return () => {
      if (obj1) {
        obj1.addEventListener("scroll", () => { });
      }
    };
  }, []);


  const logMessage = (level, message, object) => {
    isDev && console.log('[' + new Date().toLocaleTimeString() + '][' + level + ']: ', message, object ? object : '');
  }



  const resumeChat = (command) => {
    logMessage('Info', `In resume chat, socket: `, socket.current);
    if (sessionData && sessionData.id) {
      ongoing.current = sessionData.id;
      logMessage('Info', `Session ID is present`, sessionData.id);
    }
    else {
      logMessage('Error', `No Session ID so reloading`);
      logMessage('Reload', 'No session 2')
      // reloadApp();
    }
    if (ongoing.current && localStorage.getItem("session-token")) {
      logMessage('Info', 'Conecting api with id:' + ongoing.current);
      handleStart(0);
      createApp(command);
    }
    else {
      logMessage('Error', `No session value so reloading`);
      logMessage('Reload', 'No session 3')
      // reloadApp();
    }
  }

  const newChat = (command) => {
    logMessage('Info', `Creating New session`);

    if (localStorage.getItem("session-token")) {
      logMessage('Info', `Using session token:`, localStorage.getItem("session-token"));
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", localStorage.getItem("session-token"));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };

      fetch(
        "/api/v1/app_builder/apps/sessions/" + userId,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          try {
            var session_details = JSON.parse(result);
            ongoing.current = session_details.data.id;
            logMessage('Info', `New session created`, session_details.data.id);
            dispatch(addCurrApp({
              id: session_details.data.id,
            }
            ));
            handleStart(0);
            createApp(command);
          }
          catch (e) {
            logMessage('Error', `Error in creating session`, e);
          }
        })
        .catch((error) => logMessage('Error', `Error in create session TRY CATCH`, error));
    }
    else {
      logMessage('Error', `No session token so reloading`);
      logMessage('Reload', 'No session 4')
      // reloadApp();
    }
  };



  const createApp = (command) => {

    if (ongoing.current && localStorage.getItem("session-token")) {
      logMessage('Info', `New app Using session token:`, localStorage.getItem("session-token"));
      logMessage('Info', `New app using session Id:`, ongoing.current);
      logMessage('Info', `New app command:`, command);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", localStorage.getItem("session-token"));
      var formdata = new FormData();
      formdata.append("user_id", userId);
      formdata.append("prompt", command);
      formdata.append("model", 0);
      // formdata.append("model", localStorage.getItem("ai_gpt4") == 'true' ? 1 : 0);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
        credentials: "include",
      };

      fetch(
        "/api/v1/app_builder/apps/" + ongoing.current,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          try {
            var data = JSON.parse(result);
            logMessage('Info', `Current status of app is:`, data.data.state);
            if (data.data.state == 'queued') {
              logMessage('Info', `Ping start for next status with id:`, data.data.id);
              getAppStatus(data.data.id);
            }
          }
          catch (e) {
            handleStop();
            setIsLoading(false);
            logMessage('Error', `Error in creating apps`, e);
            dispatch(addCurrApp({
              app: {
                error: "Sorry we couldn't build this app. Please try another prompt[ex004].",
              }
            }
            ));
          }
        })
        .catch((error) => {
          handleStop();
          setIsLoading(false);
          logMessage('Error', `Error in creating apps TRY CATCH`, error);
          dispatch(addCurrApp({
            app: {
              error: "Sorry we couldn't build this app. Please try another prompt[ex005].",
            }
          }
          ));
        });
    }
    else {
      handleStop();
      setIsLoading(false);
      logMessage('Error', `No session found so reloading from createApp function`);
      logMessage('Reload', 'No session  5')
      // reloadApp();

    }

  }


  const getAppStatus = (task_id) => {

    if (ongoing.current && localStorage.getItem("session-token")) {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", localStorage.getItem("session-token"));
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };
      fetch("/api/v1/app_builder/apps/" + ongoing.current + '/status/' + task_id, requestOptions)
        .then((response) => {
          return response.text();
        })
        .then((result) => {

          try {
            var data = JSON.parse(result);
            logMessage('Info', `Current status of app is:`, data.data.state);
            if (data.data.state === 'ready') {
              logMessage('Info', `App is ready with app id:`, data.data.app_id);
              ongoing.current = false;
              currRequest.current = null;
              handleStop();
              getApp(data.data.app_id);
            }
            else if (data.data.state === 'failed') {
              setIsLoading(false);
              handleStop();
              logMessage('Error', `Received an error when creating app`, data.data.message);
              currRequest.current = null;
              ongoing.current = false;
              dispatch(addCurrApp({
                app: {
                  error: "Sorry we couldn't build this app. Please try another prompt[ex001].",
                }
              }
              ));
            }
            else {
              logMessage('Info', `Pinging for next status with id:`, data.data.id);
              if (ongoing.current) {
                setTimeout(() => {
                  getAppStatus(data.data.id)
                }, 5000);
              }

            }
          }
          catch (e) {
            handleStop();
            logMessage('Error', `Error in fetching status`, e);
            // setTimeout(() => {
            //   getAppStatus(task_id)
            // }, 5000);

          }
        })
        .catch((error) => {
          handleStop(); logMessage('Error', `Error in getAppStatus TRY CATCH`, error);
          setTimeout(() => {
            getAppStatus(task_id)
          }, 5000);
        });
    }
    else {
      handleStop();
      logMessage('Error', `No session token so reloading`);
      logMessage('Reload', 'No session 6')
      // reloadApp();
      // setTimeout(() => {
      //   getAppStatus(task_id)
      // }, 5000);
    }
  }

  const getApp = (app_id) => {
    if (localStorage.getItem("session-token")) {
      logMessage('Info', `Fetching app details width app_id:`, app_id);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("TOKEN", localStorage.getItem("session-token"));
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };
      fetch("/api/v1/apps/" + userId + "/" + app_id, requestOptions)
        .then((response) => {
          return response.text();
        })
        .then((result) => {

          try {
            var data = JSON.parse(result);
            logMessage('Info', `App details received:`, data);
            setIsLoading(false);
            // ongoing.current = false;
            if (data.data.app) {
              currRequest.current = null;
              ongoing.current = false;
              // data.app.prompt = null;
              dispatch(addCurrApp({
                app: data.data.app
              }
              ));
            }
            else {
              setIsLoading(false);
              logMessage('Error', `Recieved a reply but without app:`, data);
              currRequest.current = null;
              ongoing.current = false;
              dispatch(addCurrApp({
                app: {
                  error: "Sorry we couldn't build this app. Please try another prompt[ex002].",
                }
              }
              ));
            }
          }
          catch (e) {
            setIsLoading(false);
            logMessage('Error', `Error in fetching app details:`, e);
            dispatch(addCurrApp({
              app: {
                error: "Sorry we couldn't build this app. Please try another prompt[ex002].",
              }
            }
            ));

          }
        })
        .catch((error) => {
          setIsLoading(false);
          logMessage('Error', `Error in getApp TRY CATCH`, error);
          dispatch(addCurrApp({
            app: {
              error: "Sorry we couldn't build this app. Please try another prompt[ex002].",
            }
          }
          ));
        });
    }
    else {
      logMessage('Error', `No session token so reloading`);
      logMessage('Reload', 'No session 7')
      // reloadApp();
    }
  }
  //funtion for setting example command to the input
  const setExampleCommand = (val) => {
    setInput(val);
  };

  const onSelectIdea = (val) => {
    setInput(val);
    setpromptLength(val.length);
  }
  const scrollRef = useRef();
  const scrollToBottomButtonRef = useRef();

  const [isTourOpen, setisTourOpen] = useState(false);
  const [showDummy, setshowDummy] = useState(false);
  const [showDummy2, setshowDummy2] = useState(false);
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);
  const closeTour = () => {
    const cube = document.getElementById("image");
    cube.classList.remove("rotated-image");
    setisTourOpen(false);
    setshowDummy(false);
    setshowDummy2(false);
    setInput('');
    if (width >= 820) {
      dispatch(setToogle({ toogle: true }));
    }
    if (width < 820) {
      dispatch(setToogle({ toogle: false }));
    }
  };

  const openTour = () => {
    setisTourOpen(true);

  };
  const divStyle = {   // To fade in/out the div
    transition: 'width 0.5s',
  };
  const loapApp = useSelector((state) => state.history.loapApp);
  const [loadedAppUrl, setloadedAppUrl] = useState(null)
  useEffect(() => {
    // console.log(loapApp);
    if (loapApp) {
      setloadedAppUrl(loapApp)
    }
    else {
      setloadedAppUrl(null)

    }
  }, [loapApp])
  return (

    <div style={{ height: '100%' }} className="flex w-[100%] relative">
      {/* {metamaskPay && <MetamaskPay setVisible={(val) => {
        setmetamaskPay(false)
      }} />} */}
      {newsletter && <Newsletter hasSetting={hasuserSetting} setNewsletter={(val) => {
        setnewsletter(val)
      }} />}
      {showDummy && <DummyAppScreen />}
      {
        loadedAppUrl && <FullScreenApp loadurl={loadedAppUrl} />
      }
      {showDummy2 && <DummyScreenMagicLamp />}
      <main className="flex dark:bg-[#343541] bg-[#FFFFFF] w-full relative text-center overflow-hidden" style={{ flexDirection: toogleSidebar ? "row" : "column" }}>
        {(!toogleSidebar && headerVisible) ? (
          <div style={{ alignItems: "center", padding: 10 }} className="w-full border-b border-[#4D4D4F] flex justify-between">
            <div
              onClick={() => {
                dispatch(setToogle({ toogle: true }));
              }}
            >
              {darkMode === "dark" ? (
                <FiMenu style={{ fontSize: "1.5rem", color: "white" }} />
              ) : (
                <FiMenu style={{ fontSize: "1.5rem", color: "black" }} />
              )}
            </div>
            <img className="h-[2rem]" src={appLogoWithName} alt="appstorm-logo" />


            <button className="magic-button" id='magic-lamp' style={{ opacity: isLoading || !sessionData ? 0.4 : 1 }} disabled={isLoading || !sessionData ? true : false} onClick={(evt) => {
              var button = evt.currentTarget;
              button.className = button.className + ' icon-shake';
              getMagicCommand();

            }}

            >
              {darkMode === "dark" ? (
                <GiMagicLamp style={{ fontSize: "1.5rem", color: "white" }} />
              ) : (
                <GiMagicLamp style={{ fontSize: "1.5rem", color: "black" }} />
              )}
            </button>

          </div>
        ) : (width >= 820 &&
          <div className="floating-lamp">
            <button className="magic-button" id='magic-lamp' style={{ opacity: isLoading || !sessionData ? 0.4 : 1 }} disabled={isLoading || !sessionData ? true : false} onClick={(evt) => {
              var button = evt.currentTarget;
              button.className = button.className + ' icon-shake';
              getMagicCommand();

            }}

            >
              {darkMode === "dark" ? (
                <GiMagicLamp style={{ fontSize: "1.5rem", color: "white" }} />
              ) : (
                <GiMagicLamp style={{ fontSize: "1.5rem", color: "white" }} />
              )}
            </button>
          </div>
        )}

        <div style={{ width: 300, display: toogleSidebar ? 'block' : "none", height: "100%", zIndex: 90, position: width <= 768 ? "absolute" : "initial" }}>
          <Sidebar isFullscreen={toogleSidebar} changeChat={(id) => {

            const element = document.getElementById('app' + id);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }}
            newChat={() => {
            }}
            showonboarding={() => {

              openTour();
            }}
            onLogOut={() => {

            }
            }
          />
        </div>
        <div style={{ flex: 1, flexDirection: "column", display: "flex", overflow: "auto" }} className={'dark:border-[#2A2B32]'}>
          <div style={{ flex: 1, display: "flex", overflowY: "auto", overflowX: "hidden" }} ref={scrollRef}>
            {!sessionData ? (
              <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <TailSpin
                  height="40"
                  width="40"
                  color="#ffffff"
                  ariaLabel="tail-spin-loading"
                  radius="0.1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>

            )
              :
              appList.length > 0 ?
                (<div style={{ flex: 1 }} >
                  {/* {chatFeed.map((curr, index) => {
                  return ( */}

                  <ChatComp
                    onSelectIdea={onSelectIdea}
                  />
                </div>
                ) :
                <div
                  className={` w-full overflow-auto dark:bg-[#343541] bg-slate-200 flex flex-col gap-4 justify-center items-center`}
                >
                  {toogleSidebar && <div className="dark:text-[#ECECF1] text-[#343541] text-4xl font-semibold  mt-[3rem]">
                    <img className="h-[4rem]" src={appLogoWithName} alt="appstorm-logo" />
                  </div>
                  }
                  <div className="text-center text-[#343541] dark:text-[#E3E3E9] flex flex-col items-center justify-center gap-2 mb-3">
                    <BsSun style={{ fontSize: "1.5rem" }} />
                    Examples
                  </div>
                  <div
                    className={`${width <= 768 ? "w-[80%] -top-12" : "w-[65%]"
                      } h-[50vh] flex gap-3 justify-center relative flex-wrap`}
                  >
                    <div
                      className={`${width <= 768 ? "w-full" : "w-[14rem]"
                        } text-white`}
                    >
                      {ideas.slice(0, 3).map((val, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => setExampleCommand(val.idea)}
                            className="text-ellipsis cursor-pointer text-sm p-3 break-words bg-[#F7F7F8] text-[#343541] dark:bg-[#3E3F4B] dark:text-[#E3E3E9] rounded-md dark:shadow-md mb-3"
                          >
                            {
                              val.idea
                            }
                          </div>
                        )
                      })}


                    </div>
                    <div
                      className={`${width <= 768 ? "w-full" : "w-[14rem]"
                        } text-white`}
                    >
                      {ideas.slice(3, 6).map((val, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => setExampleCommand(val.idea)}
                            className="text-ellipsis cursor-pointer text-sm p-3 break-words bg-[#F7F7F8] text-[#343541] dark:bg-[#3E3F4B] dark:text-[#E3E3E9] rounded-md dark:shadow-md mb-3"
                          >
                            {
                              val.idea
                            }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
            }

          </div>
          <div style={{
            display: "flex", position: "relative", justifyContent: "center", padding: 10, minHeight: 80, backgroundColor: darkMode === "dark"
              ? "#33343e"
              : "#E2E8F0"
          }}>
            <button
              ref={scrollToBottomButtonRef}
              onClick={() => {
                scrollToBottom();
              }}
              style={{ top: -60 }}
              title='Scroll to bottom'
              className={`rounded-2xl absolute right-4 dark:bg-[#535561] bg-[#FFFFFF] p-1 dark:border-[white] shadow-md dark:shadow-none border-[#343541] border`}
            >
              <AiOutlineArrowDown
                style={{ color: darkMode === "dark" ? "white" : "#343541" }}
              />
            </button>
            <div style={{ maxWidth: 800, transition: 'width 1.5s' }} className="w-full h-full flex flex-col gap-2 relative app-bar" >

              <div className="flex items-center" style={{ display: "flex" }}>
                <div className=" p-2 flex-1 bg-[#FFFFFF] border border-[#E5E5E5] dark:border-none dark:bg-[#40414F] shadow-2xl rounded-md flex justify-between pr-2 gap-3" style={{ minWidth: 0, alignItems: 'center' }}>
                  {!showtooltip && <div className="tooltip" style={{ position: "absolute", top: -61, left: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }} className="bg-[#c5caed] px-2 py-2 rounded">
                      <div className="text-grey-400 text-sm w-[6rem] h-auto font-bold">Get ideas!</div>
                      <RxCross1 onClick={closeToolTip} style={{ cursor: 'pointer' }}></RxCross1>
                    </div>
                    <div className="arrow-down tooltip relative bottom-[7px]"></div>
                  </div>}

                  <div
                    id="appstormId"
                    onClick={clickclick}
                    className="rounded-sm text-xl relative appstorm-logo"
                    alt="Idea"
                    style={{ padding: 5, marginRight: 5, width: 40, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                  >
                    <img id="image" alt="app logo" src={appLogo} />
                  </div>
                  <textarea
                    id='appstormtextId'
                    style={{ flex: 1, maxHeight: 180 }}
                    disabled={isLoading || !sessionData}
                    type="text"
                    ref={chatRef}
                    rows={1}
                    maxLength={144}
                    // maxLength={144}
                    className="prompt-area focus:outline-none p-2 bg-transparent text-[#2F2F2F] resize-none dark:text-white whitespace-pre-wrap"
                    // onInput={e => {
                    //   e.target.style.height = '24px' // this will hit once we remove text
                    //   e.target.style.height = e.target.scrollHeight + 'px' // this will hit on increase text.
                    // }}
                    autoFocus
                    // placeholder={loadingTexts}
                    placeholder='Chatbot that....'
                    value={input}
                    onChange={(e) => {
                      e.preventDefault();
                      setInput(e.target.value);
                      setpromptLength(e.target.value.length)
                    }}
                    onKeyUp={(e) => {
                      e.preventDefault();
                      if (!e.shiftKey && e.key === "Enter") {
                        generateResponse();
                      }
                    }}
                  />
                  {toogleSidebar && promptLength > 0 && <div style={{ display: 'flex', alignItems: "center", color: '#9ca3af', fontSize: '0.9rem' }}>
                    {promptLength}/144
                  </div>}
                  {/* {
                    isLoading || !sessionData ?

                      <Oval
                        height={30}
                        width={30}
                        color="#ff7c01"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#ff7c01"
                        strokeWidth={5}
                        strokeWidthSecondary={5}

                      />
                      : */}
                  <button
                    disabled={isLoading || !sessionData ? true : false}
                    id='sendbuttonId'
                    onClick={() => {
                      generateResponse();
                      document.getElementsByTagName('textarea')[0].style.height = 'auto'
                    }}
                    className="p-2 text-white"
                    style={{ opacity: isLoading || !sessionData ? '0.3' : '1', height: 30, width: 30, borderRadius: 2 }}
                  >
                    <RiSendPlane2Fill
                      style={{
                        color: darkMode === "light" ? "rgb(149 149 149)" : "#fff",
                      }}
                    />
                  </button>
                  {/* } */}
                </div>


              </div>
            </div>
            {/* <button className="p-2 text-white" onClick={() => {
                document.getElementsByClassName('app-bar')[0].style.width = '80px';
                // document.getElementById('appstormtextId').placeholder = '';
                setTimeout(() => {
                  setmode('Gallery')
                }, 1000)
              }}> <MdOutlineViewInAr
                  style={{
                    color: darkMode === "light" ? "rgb(149 149 149)" : "#fff",
                  }}
                /></button> */}
          </div>
        </div>

      </main>
      <Tour
        onRequestClose={closeTour}
        disableInteraction={true}
        steps={steps}
        isOpen={isTourOpen}
        maskClassName="mask"
        startAt={0}
        className="helper"
        rounded={5}
        showNavigation={false}
        accentColor='#5cb7b7'
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showNumber={false}
        lastStepNextButton={<button style={{ width: 100, height: 40 }}
          className="px-3 py-2 rounded-md bg-[#10A37F] text-white"
        >Let's Go!</button>}
        disableDotsNavigation={true}
      />
    </div>


  );
};

export default HomePage;
