import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDarkModeState } from '../features/HistorySlice'
import { Switch } from '@headlessui/react'

const SwitchComp = () => {
    const dispatch = useDispatch()
    // const darkModeState = useSelector(state => state.history.darkModeState)


    const [enabled, setEnabled] = useState(localStorage.getItem('darkMode') ? (localStorage.getItem('darkMode') === 'dark' ? true : false) : true)
    // console.log(darkModeState)
    return (

        <Switch
            checked={enabled}
            onChange={setEnabled}
            onClick={() => {
                dispatch(setDarkModeState())
            }}
            className={`${enabled ? 'bg-[#10A37F]' : 'bg-[#D9D9E3]'}
          relative inline-flex h-[38px] scale-50 w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
        </Switch>
    )
}

export default SwitchComp