import React, { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import '../App.css'

const PasswordInput = ({ setPassword, placeholder, value, onKeyUp, id, disabled = false }) => {
    const [visible, setVisible] = useState(false)
    // useEffect(() => { console.log(value) }, [value])
    return (
        <div className="inputbox">

            <input
                id={id}
                className='focus:outline-none border-none'
                type={visible ? 'text' : 'password'}
                maxLength={50}
                value={value}
                required
                disabled={disabled}
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
                onKeyUp={onKeyUp}
            />
            <span>{placeholder}</span>

            <button style={{ color: '#10a37f' }} onClick={() => setVisible(!visible)} className='p-2 absolute right-2 bottom-3'>
                {
                    visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                }
            </button>
        </div>
    )
}

export default PasswordInput