import React, { useState, useEffect } from "react";
import { loginUser } from "../Conn/login";
import { useDispatch } from "react-redux";
import { setUserAndId } from "../features/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import appLogo from "../Assets/appLogo.png";
import PasswordInput from "../Components/PasswordInput";
import '../App.css'
import { fetchToken } from "../Conn/session";

const Password = ({ route }) => {
    const [seconds, setSeconds] = useState(0);
    // const { email } = route.params;
    const [email, setEmail] = useState('');
    const [forgetPassmail, setforgetPassmail] = useState(false);
    const [resendMail, setresendMail] = useState(false);
    const [password, setPassword] = useState("");
    const [errorMsg, seterrorMsg] = useState(" ");
    const [isLoading, setIsLoaing] = useState(false);
    const isDev = process.env.NODE_ENV === 'development' ? true : false;
    const [sentmail, setsentmail] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mail } = useParams();
    const testEmail = (email) => {
        let regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };


    useEffect(() => {

        if (mail) {

            setEmail(atob(mail));
        }
        else {
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const login = () => {
        if (!email) {
            seterrorMsg("Please enter email");
            return;
        }
        if (!password) {
            seterrorMsg("Please enter password");
            return;
        }
        if (testEmail(email)) {
            setIsLoaing(true);
            fetchToken((val) => {
                if (val.status === 'success') {
                    loginUser(
                        {
                            email: email,
                            password: password,
                        },
                        (val) => {
                            if (val.user) {
                                // console.log(val.user);
                                if (val.user.user.is_active) {
                                    dispatch(
                                        setUserAndId({
                                            email: val.user.user.email,
                                            id: val.user.user.id,
                                        })
                                    );
                                    // setEmail("");
                                    setPassword("");
                                    setIsLoaing(false);
                                    navigate("/");
                                }
                                else {
                                    seterrorMsg("Account not activated. Kindly check your mail.");
                                    setresendMail(true);
                                }

                            } else {
                                setIsLoaing(false);
                                if (val.message) {
                                    // alert(val.message);
                                    if (val.message === 'Account not activated') {
                                        seterrorMsg("Account not activated. Kindly check your mail.");
                                        setresendMail(true);

                                    }
                                    else {
                                        seterrorMsg(val.message);
                                    }
                                }
                                else {
                                    isDev && alert('Error in login');
                                }
                            }
                        }
                    );
                }
                else {
                    alert('Error in creating session');
                    setIsLoaing(false);

                }
            })

        } else {
            seterrorMsg("Enter the valid credentials");
        }
        // console.log(email,password)
    };
    const forgotPassword = () => {
        if (email) {
            setIsLoaing(true);
            fetchToken((val) => {
                if (val.status === 'success') {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("TOKEN", localStorage.getItem("session-token"));
                    var requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow",
                        credentials: "include",
                    };
                    fetch("/api/v1/users/forgot_password?email=" + email, requestOptions)
                        .then((response) => {
                            return response.text();
                        })
                        .then((result) => {

                            try {
                                var val = JSON.parse(result);
                                if (val.status === 200) {
                                    setIsLoaing(false);
                                    setsentmail(true);
                                    setforgetPassmail(true);
                                    setSeconds(20);
                                }
                                else {
                                    if (val.message) {
                                        seterrorMsg(val.message);
                                    }
                                    else {
                                        seterrorMsg('Error in sending mail. Try agin later');
                                        // isDev && alert('Error in login');
                                    }
                                    setsentmail(false); setIsLoaing(false); setresendMail(false);

                                }
                            }
                            catch (e) {
                                seterrorMsg('Error in sending mail. Try agin later'); setsentmail(false); setIsLoaing(false); setresendMail(false);
                            }
                        })
                        .catch((error) => { seterrorMsg('Error in sending mail. Try agin later'); console.log("error", error); setsentmail(false); setIsLoaing(false); setresendMail(false); });
                }
                else {
                    alert('Error in creating session');
                    setIsLoaing(false);

                }
            })
        }
        else {
            seterrorMsg('Kindly provide registered email id.');
        }
    }


    const resendActiveMail = () => {
        setIsLoaing(true);
        fetchToken((val) => {
            if (val.status === 'success') {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("TOKEN", localStorage.getItem("session-token"));
                var requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                    credentials: "include",
                };
                fetch("/api/v1/resend_activation_link?email=" + email, requestOptions)
                    .then((response) => {
                        return response.text();
                    })
                    .then((result) => {

                        try {
                            if (JSON.parse(result).status === 201) {
                                setIsLoaing(false);
                                setforgetPassmail(false);
                                setsentmail(true);
                                setSeconds(20);
                            }
                            else {
                                seterrorMsg('Error in sending mail. Try agin later'); setsentmail(false); setIsLoaing(false); setresendMail(false);
                            }
                        }
                        catch (e) {
                            seterrorMsg('Error in sending mail. Try agin later'); setsentmail(false); setIsLoaing(false); setresendMail(false);
                        }
                    })
                    .catch((error) => { seterrorMsg('Error in sending mail. Try agin later'); console.log("error", error); setsentmail(false); setIsLoaing(false); setresendMail(false); });
            }
            else {
                alert('Error in creating session');
                setIsLoaing(false);

            }
        })

    }

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [seconds]);
    return (
        <main className="w-full flex justify-center items-center bg-[#f6f6f6]" style={{ flexDirection: "column", padding: 20, height: '100%', minHeight: 550 }} >
            <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
                {isLoading ? (
                    <h1 className="text-xl" style={{ color: "black" }}>Loading...</h1>
                ) : (
                    <section className="flex flex-col w-[18rem]">
                        {sentmail ? <div className="flex flex-col gap-4">
                            <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
                            <h1 className="text-center text-3xl font-semibold text-black">
                                Verify your email
                            </h1>

                            <div style={{ textAlign: "center", color: "black" }}>We have sent an email to</div>
                            <div style={{ textAlign: "center", color: "black" }}>{email}</div>
                            <div style={{ textAlign: "center", color: "black" }}>Click the activation link to get started.</div>
                            {!forgetPassmail && <div style={{ textAlign: "center", color: "black", fontSize: 15 }}>{seconds > 0 ? ('Resend Link in ' + seconds) : (<span onClick={resendActiveMail} style={{ textAlign: "left", color: "black", fontSize: 15, textDecoration: 'underline', cursor: 'pointer' }}>Resend Mail</span>)}</div>}
                            <button
                                onClick={() => { setsentmail(false); seterrorMsg(" "); setresendMail(false); navigate('/login') }}
                                className="bg-[#10a37f] text-white p-3 rounded-sm"
                            >
                                Go to Login
                            </button>
                        </div> : <div className="flex flex-col gap-4">
                            <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
                            <h1 className="text-center text-2xl font-semibold" style={{ color: "black" }}>
                                Enter your password
                            </h1>
                            <div className="inputbox border border-[#10a37f]" style={{ display: "flex" }}>
                                <input
                                    disabled={true}
                                    id="emailId"
                                    className="p-3 focus:outline-none w-full"
                                    style={{ border: 0 }}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        // setEmail(e.target.value);
                                    }}
                                    value={email}
                                />
                                {/* <span>Email</span> */}
                                <button
                                    onClick={() => { navigate('/login') }}
                                    className="rounded-sm"
                                    style={{ marginRight: 10, color: '#10a37f' }}
                                >
                                    Edit
                                </button>
                            </div>
                            <PasswordInput
                                id='passId'
                                style={{ backgroundColor: "black" }}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        login();
                                    }
                                }}
                                value={password}
                                setPassword={setPassword}
                                placeholder="Password"
                            />
                            <div onClick={forgotPassword} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Forgot Password?</div>
                            <div style={{ textAlign: "left", color: "red", fontSize: 15 }}>{errorMsg} {resendMail && <span onClick={resendActiveMail} style={{ textAlign: "left", color: "black", fontSize: 15, textDecoration: 'underline', cursor: 'pointer' }}>Resend Mail</span>}</div>

                            <button
                                id='loginButtonId'
                                onClick={login}
                                className="bg-[#10a37f] text-white p-3 rounded-sm"
                            >
                                Log in
                            </button>
                            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
                                <span className="text-black text-center">Don't have an account? </span><button onClick={() => navigate('/register')} title="Go to Registration Page" className="text-center" style={{ marginLeft: 10, color: '#10a37f' }}>Sign Up</button>
                            </div>
                            {/* <button onClick={() => navigate('/register')} title="Go to Registration Page" className="text-black text-center">Don't have an account? Register</button> */}
                        </div>}

                    </section>
                )
                }
            </div>
            {/* <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px", maxWidth: 345 }}>
                <span className="text-center" style={{ color: "black", fontSize: 15 }}>
                    By logging into Appstorm, you are agreeing to our <span onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</span> and <span onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</span>
                </span>
            </div> */}
            <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px" }}>
                <div onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</div>
                <div style={{ textAlign: "left", color: "black", fontSize: 15, margin: "0px 10px" }}>|</div>
                <div onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</div>
            </div>
        </main >
    );
};

export default Password;
