import { createSlice } from "@reduxjs/toolkit"

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        id: null
    },
    reducers: {
        setUserAndId: (state, action) => {
            state.user = action.payload.email
            state.id = action.payload.id
        },
        logout: (state, action) => {
            state.user = null;
            state.id = null;
            localStorage.removeItem("session-token");
            localStorage.removeItem("appstorm-user");
            localStorage.removeItem('set-tooltip');
        },
    }
})

export const { setUserAndId, logout } = authSlice.actions
export default authSlice.reducer