import React from 'react'
import appLogo from "../Assets/favicon-appstorm.png";
import { useDispatch, useSelector } from 'react-redux'
import { setEmailNotification } from '../features/HistorySlice'
function ReportApp({ setVisible, appId }) {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.id);

    const sendReport = (value) => {

        var myHeaders = new Headers();
        myHeaders.append("TOKEN", localStorage.getItem("session-token"));
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
            credentials: "include",
        };

        fetch("/api/v1/apps/logs/" + appId, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                // console.log(result);
                // setNewsletter(value);
                setVisible(true)
            })
            .catch((error) => { console.log("error", error); });
    }
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.8', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <div className={`flex gap-2 bg-[#f6f6f6] `} style={{ padding: 20, flexDirection: 'column', maxWidth: 400, borderRadius: 10, marginTop: "5%", color: 'black', marginRight: 30, marginLeft: 30 }}>

                <h4>Do you want to report this app?</h4>
                <div className='flex justify-end'>
                    <button
                        onClick={() => sendReport()}
                        className="px-3 py-2 rounded-md bg-[#10A37F] text-white"
                        style={{ fontSize: 13 }}
                    >Yes</button>
                    <button
                        onClick={() => setVisible(false)}
                        style={{ fontSize: 13, marginLeft: 10 }}
                    >Cancel</button>
                </div>
            </div>
        </div>

    )
}

export default ReportApp