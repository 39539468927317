import React from 'react'
import appLogo from "../Assets/favicon-appstorm.png";

function CookiePolicy({ setCookie }) {
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.8', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <div className={`flex gap-2 bg-[#f6f6f6] `} style={{ padding: 20, flexDirection: 'column', maxWidth: 400, borderRadius: 10, marginTop: "5%", color: 'black', marginRight: 30, marginLeft: 30 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', borderBottomWidth: 1, borderBottomColor: '#0000006b', paddingBottom: 10 }}> <img id="image" alt="app logo" src={appLogo} width={30} /><h1 style={{ fontSize: 20 }}><strong>Welcome to Appstorm!</strong></h1>
                </div>
                <h4>Build an AI app in seconds.</h4>
                <p>How it works:</p>
                <span style={{ color: 'rgba(0,0,0,1)' }}>
                    <i>1 - Type your idea as a prompt. Or click on the tornado icon to get an idea.</i><br /><br />
                    <i>2 - Our properietary tech leverages the best AI models to create an app.</i><br /><br />
                    <i>3 - Take the app for a test run, share with your friends.</i>
                </span>

                <h5 style={{ borderTopWidth: 1, borderTopColor: '#0000006b', paddingTop: 10, marginTop: 10, color: '#000000a6' }}>This website uses cookies to enhance the user experience.</h5>
                <div className='flex justify-end'>
                    <button
                        onClick={setCookie}
                        className="px-3 py-2 rounded-md bg-[#10A37F] text-white"
                        style={{ fontSize: 13 }}
                    >Accept</button>
                </div>
            </div>
        </div>

    )
}

export default CookiePolicy