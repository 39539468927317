import React, { useState } from 'react'
import { setLoadAppUrl } from "../features/HistorySlice";
import { useSelector, useDispatch } from "react-redux";
import ShareBox from "./ShareBox";
import { removeApp, changeActionS } from "../features/HistorySlice";
import { RiShareForwardLine } from 'react-icons/ri';
import { FiAlertCircle } from "react-icons/fi";
import ReportApp from "./ReportApp";

function FullScreenApp({ loadurl }) {
    const dispatch = useDispatch();
    const [share, setShare] = useState(false);
    const [app_id, setapp_id] = useState(null);
    const [reportapp, setreportapp] = useState(false);

    const reportApp = () => {
        setreportapp(true)
    }


    const shareApp = () => {
        // var appData = { ...val };
        // setShare(true);
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", localStorage.getItem("session-token"));

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            credentials: "include",
            redirect: "follow",
        };

        fetch("/api/v1/apps/share/" + loadurl.id, requestOptions)
            .then((response) => response.text())
            .then(async (result) => {
                // console.log(result);
                try {
                    if (JSON.parse(result) && JSON.parse(result).status === 200) {
                        setapp_id(JSON.parse(result).data.id);
                        setShare(true);

                        // setSharedUrl(appData);

                        dispatch(changeActionS({ id: JSON.parse(result).data.id }));
                    }
                    else {
                        setShare(false);
                        // isDev && alert(JSON.parse(result).message);
                    }
                }
                catch (e) {
                    setShare(false);
                    //   isDev && alert(JSON.parse(result).message);
                }
            })

            .catch((error) => { setShare(false); console.log("error", error) });

    }
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.95', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {reportapp && <ReportApp appId={loadurl.id} setVisible={(val) => {
                setreportapp(false)
            }} />}

            {share && <ShareBox setShare={setShare} app_id={app_id} app_name={loadurl?.app_name} />}
            <div className={`flex`} style={{ padding: 20, flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: 640, justifyContent: 'end', alignItems: 'center', gap: 20, paddingBottom: 10 }}>
                    <FiAlertCircle title="Report app" style={{ cursor: 'pointer', color: 'white' }} size={25} onClick={() => {
                        reportApp()
                    }}></FiAlertCircle>
                    <RiShareForwardLine style={{ cursor: 'pointer', color: 'white' }} size={25} onClick={() => {
                        shareApp()
                    }}></RiShareForwardLine>
                    <div onClick={() => {
                        dispatch(setLoadAppUrl(''));
                    }} style={{ color: 'white', textAlign: 'right', cursor: 'pointer' }}>Close [X]</div>
                </div>
                <div style={{
                    backgroundColor: '#a7a6a6',
                    borderRadius: 10,
                    overflowY: 'auto',
                    maxHeight: '100%',
                    maxWidth: 640,
                    width: '100%',
                    height: '100%'
                }}>
                    <iframe height='100%' width='100%' src={loadurl?.remote_endpoint}></iframe>
                </div>
            </div>
        </div>

    )
}

export default FullScreenApp