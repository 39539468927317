import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setUserAndId } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import appLogo from "../Assets/appLogo.png";
import '../App.css';
import { fetchToken } from "../Conn/session";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const RegisterMobile = () => {
    const [errorMsg, seterrorMsg] = useState(" ");
    const [phone, setphone] = useState("");
    const [username, setusername] = useState("");
    const [listenOtp, setlistenOtp] = useState(false);
    const [otp, setotp] = useState([]);
    const [isLoading, setIsLoaing] = useState(false)
    // const isDev = process.env.NODE_ENV === 'development' ? true : false;
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(0);

    const register = () => {
        if (!phone) {
            seterrorMsg("Provide valid phone number");
            return;
        }
        if (!username) {
            seterrorMsg("Provide valid username");
            return;
        }
        if (otp.join('').length !== 6) {
            seterrorMsg("Provide valid OTP");
            return;
        }

        setIsLoaing(true);
        var token = localStorage.getItem("session-token");
        var formdata = new FormData();
        formdata.append("phone", phone);
        formdata.append("user_name", username);
        formdata.append("otp", otp.join(''));
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", token);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: formdata,
            credentials: "include",
        };
        fetch("/api/v1/register/phone", requestOptions)
            .then((response) => {
                return response.text();
            })
            .then((result) => {
                // console.log(result);
                try {
                    var val = JSON.parse(result);
                    if (val.status === 201 && val.data.user) {

                        setIsLoaing(false);
                        localStorage.setItem("session-token", val.data.token);
                        localStorage.setItem("appstorm-user", val.data.user.id);
                        dispatch(
                            setUserAndId({
                                email: val.data.user.user_name,
                                id: val.data.user.id,
                            })
                        );
                        setIsLoaing(false);
                        navigate("/");
                    }
                    else {
                        if (val.message) {
                            seterrorMsg(val.message);
                        }
                        else {
                            seterrorMsg("Error in registering user");
                        }
                        setIsLoaing(false);
                    }
                }
                catch (e) {
                    alert("Otp expired. Try again")
                    setIsLoaing(false);

                }

            })
            .catch((error) => { console.log("error", error); setIsLoaing(false); });


    }

    useEffect(() => {
        if (listenOtp) {
            inputRefs[0].current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listenOtp])

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [seconds]);
    const sendOtp = () => {
        // var regex = new RegExp('^[+]{1}(?:[0-9]\s?){6, 15}[0-9]{1}$');
        if (!phone) {
            seterrorMsg("Provide valid phone number");
            return;
        }
        if (!username) {
            seterrorMsg("Provide valid username");
            return;
        }
        if (!isValidPhoneNumber(phone)) {
            seterrorMsg("Provide valid phone number");
            return;

        }
        seterrorMsg(" ");
        setIsLoaing(true);
        fetchToken((val) => {
            if (val.status === 'success') {
                var token = localStorage.getItem("session-token");
                var formdata = new FormData();
                formdata.append("phone", phone);
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("TOKEN", token);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: formdata,
                    credentials: "include",
                };
                fetch("/api/v1/generate_otp/phone/0", requestOptions)
                    .then((response) => {
                        return response.text();
                    })
                    .then((result) => {
                        // console.log(result);
                        try {
                            var val = JSON.parse(result);
                            if (val.status === 200) {
                                setlistenOtp(true);
                                setIsLoaing(false);
                                setSeconds(20);
                            }
                            else {
                                if (val.message) {
                                    seterrorMsg(val.message);
                                }
                                else {
                                    seterrorMsg("Error in registering user");
                                }

                                setIsLoaing(false);
                            }
                        }
                        catch (e) {
                            alert("Otp expired. Try again")
                            setIsLoaing(false);

                        }

                    })
                    .catch((error) => { console.log("error", error); setIsLoaing(false); });
            }
            else {
                alert('Error in creating session');
                setIsLoaing(false);
            }
        });

    }
    const handleInput = (index, event) => {
        const { value } = event.target;
        var old;
        if (value) {
            if (value.length === 1) {

                old = otp;
                old[index] = value;
                setotp([...old]);
                if (index !== 5) {
                    inputRefs[index + 1].current.focus();
                }
            }
        }
        else {
            old = otp;
            old[index] = value;
            setotp([...old]);
        }
    };


    return (
        <main className="w-full h-screen flex justify-center items-center bg-[#f6f6f6]" style={{ flexDirection: 'column' }}>
            {
                isLoading ? <h1 className="text-black">Loading...</h1> :
                    <section className="bg-transparent flex flex-col flex-1 items-center">
                        <div className="flex flex-col gap-4 flex-1 w-[18rem] justify-center">
                            <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
                            <h1 className="text-center text-3xl font-semibold text-black">
                                Create your account
                            </h1>
                            <div className="focused-input">
                                <PhoneInput
                                    international
                                    // countryCallingCodeEditable={false}
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={(e) => {
                                        setphone(e);
                                    }} />
                                {/* <input
                                    className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setphone(e.target.value);
                                    }}
                                    value={phone}
                                />
                                <span>Phone Number</span> */}
                            </div>
                            <div className="inputbox">
                                <input
                                    className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setusername(e.target.value);
                                    }}
                                    value={username}
                                />
                                <span>Username</span>
                            </div>
                            {listenOtp && <div style={{ display: "flex", flexDirection: "row" }}>
                                {/* {Array.from(6, (_, index) => ( */}
                                {[1, 2, 3, 4, 5, 6].map((_, index) => {
                                    return (<div className="inputbox"
                                        style={{ marginRight: index === 5 ? 0 : 10 }}
                                        key={index}
                                    >
                                        <input
                                            ref={inputRefs[index]}
                                            style={{ textAlign: 'center' }}
                                            className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                                            type="number"
                                            maxLength={1}
                                            required
                                            onChange={(e) => handleInput(index, e)}
                                            value={otp[index]}
                                        />
                                    </div>)
                                })}

                            </div>
                            }
                            <div style={{ textAlign: "left", color: "red", fontSize: 15 }}>{errorMsg}</div>
                            {listenOtp && <div style={{ textAlign: "center", color: "black", fontSize: 15 }}>{seconds > 0 ? ('Resend OTP in ' + seconds) : (<span onClick={sendOtp} style={{ textAlign: "left", color: "black", fontSize: 15, textDecoration: 'underline', cursor: 'pointer' }}>Resend OTP</span>)}</div>}

                            {listenOtp ? <button
                                onClick={register}
                                className="bg-[#10a37f] text-white p-3 rounded-sm"
                            >
                                Sign Up
                            </button> : <button
                                onClick={sendOtp}
                                className="bg-[#10a37f] text-white p-3 rounded-sm"
                            >
                                Send OTP
                            </button>}
                            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
                                <span className="text-black text-center">Already have an account?</span><button onClick={() => navigate('/login')} title="Go to Login Page" className="text-center" style={{ marginLeft: 10, color: '#10a37f' }}>Login</button>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px", maxWidth: 345 }}>
                            <span className="text-center" style={{ color: "black", fontSize: 15 }}>
                                By registering into Appstorm, you are agreeing to our <span onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</span> and <span onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</span>
                            </span>
                        </div>
                    </section>
            }
        </main>
    );
};

export default RegisterMobile;
