import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoutes = () => {
  const user = useSelector((state) => state.auth.user);
  return !(user === null) ? (
    <Outlet />
  ) : (
    <Navigate to={"/welcome"} />
  );
};

export default ProtectedRoutes;
