import React, { useState, useEffect, useRef } from 'react'
const ShareUrl = () => {

    useEffect(() => {
        var id = new URLSearchParams(window.location.search).get('url');
        window.location.href = window.location.origin + "/apps/" + id;
    }, []);



    return (
        <>

        </>
    )
}

export default ShareUrl