import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setUserAndId } from "../features/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import appLogo from "../Assets/appLogo.png";
import '../App.css';
import { fetchToken } from "../Conn/session";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


const Mobile = () => {
    const [errorMsg, seterrorMsg] = useState(" ");
    const [phone, setphone] = useState("");
    const [listenOtp, setlistenOtp] = useState(false);
    const [otp, setotp] = useState([]);
    const [isLoading, setIsLoaing] = useState(false)
    // const isDev = process.env.NODE_ENV === 'development' ? true : false;
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [seconds, setSeconds] = useState(0);
    const { mobile } = useParams();
    useEffect(() => {
        checkForMobile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkForMobile = () => {
        if (mobile) {

            setphone(atob(mobile));
            register(null, atob(mobile));

        }
        else {
            navigate('/');
        }
    }

    useEffect(() => {
        if (listenOtp) {
            inputRefs[0].current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listenOtp])

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [seconds]);
    const register = (event, phnumber) => {
        // var regex = new RegExp('^[+]{1}(?:[0-9]\s?){6, 15}[0-9]{1}$');
        var mob = phnumber ? phnumber : phone;
        if (!mob) {
            seterrorMsg("Provide phone number");
            return;
        }
        if (!isValidPhoneNumber(mob)) {
            seterrorMsg("Provide valid phone number");
            return;

        }
        seterrorMsg(" ");
        setIsLoaing(true);
        fetchToken((val) => {
            if (val.status === 'success') {
                var token = localStorage.getItem("session-token");
                var formdata = new FormData();
                formdata.append("phone", mob);
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("TOKEN", token);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: formdata,
                    credentials: "include",
                };
                fetch("/api/v1/generate_otp/phone/1", requestOptions)
                    .then((response) => {
                        return response.text();
                    })
                    .then((result) => {
                        // console.log(result);
                        try {
                            var val = JSON.parse(result);
                            if (val.status === 200) {
                                setlistenOtp(true);
                                setIsLoaing(false);
                                setSeconds(20);
                                // inputRefs[0].current.focus();
                            }
                            else {
                                seterrorMsg(val.message);
                                setIsLoaing(false);
                            }
                        }
                        catch (e) {
                            alert("Otp expired. Try again")
                            setIsLoaing(false);

                        }

                    })
                    .catch((error) => { console.log("error", error); setIsLoaing(false); });
            }
            else {
                alert('Error in creating session');
                setIsLoaing(false);


                // navigate("/");
            }
        });
    };



    const login = () => {
        if (!phone) {
            seterrorMsg("Provide valid phone number");
            return;
        }
        if (otp.join('').length !== 6) {
            seterrorMsg("Provide valid OTP");
            return;
        }
        setIsLoaing(true);
        var token = localStorage.getItem("session-token");
        var formdata = new FormData();
        formdata.append("phone", phone);
        formdata.append("otp", otp.join(''));
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("TOKEN", token);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: formdata,
            credentials: "include",
        };
        fetch("/api/v1/login/phone", requestOptions)
            .then((response) => {
                return response.text();
            })
            .then((result) => {
                // console.log(result);
                try {
                    var val = JSON.parse(result);
                    if (val.status === 'success' && val.data && val.data.user) {
                        localStorage.setItem("session-token", JSON.parse(result).data.token);
                        localStorage.setItem("appstorm-user", JSON.parse(result).data.user.id);
                        dispatch(
                            setUserAndId({
                                email: val.data.user.user_name,
                                id: val.data.user.id,
                            })
                        );
                        setIsLoaing(false);
                        navigate("/");
                    } else {
                        seterrorMsg(val.message);
                        setIsLoaing(false);
                        // navigate("/");
                    }
                }
                catch (e) {
                    seterrorMsg("Something failed. Try again")
                    setIsLoaing(false);
                }

            })
            .catch((error) => { console.log("error", error); setIsLoaing(false); });
    }

    const handleInput = (index, event) => {
        var value = event.target.value;
        var old;
        if (value.length > 1) {
            value = parseInt(value.toString().substring(0, 1))
        }
        if (value) {
            if (value.length === 1) {
                old = otp;
                old[index] = value;
                setotp([...old]);
                if (index !== 5) {
                    inputRefs[index + 1].current.focus();
                }
            }
        }
        else {
            old = otp;
            old[index] = value;
            setotp([...old]);
        }
    };



    return (
        <main className="w-full h-screen flex justify-center items-center bg-[#f6f6f6] flex-1" style={{ flexDirection: 'column' }}>
            {
                isLoading ? <h1>Loading...</h1> :
                    <section className="bg-transparent flex flex-col flex-1 items-center">
                        <div className="flex flex-col gap-4 flex-1 justify-center w-[18rem] ">
                            <img alt="app logo" className="mb-6" src={appLogo} style={{ maxWidth: 300 }} />
                            <h1 className="text-center text-3xl font-semibold text-black">
                                Welcome back
                            </h1>

                            <div className="focused-input">
                                <PhoneInput
                                    international
                                    // countryCallingCodeEditable={false}
                                    placeholder="Enter phone number"
                                    value={phone}
                                    error={(error) => {
                                        console.log(error)
                                    }}
                                    onChange={(e) => {
                                        seterrorMsg(' ')
                                        setphone(e);
                                    }} />
                                {/* <input
                                    // disabled={listenOtp}
                                    className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                                    // style={{ color: listenOtp ? '#ffffff8c' : 'white' }}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setphone(e.target.value);
                                    }}
                                    value={phone}

                                />
                                <span>Phone Number</span> */}
                            </div>
                            {listenOtp && <div style={{ display: "flex", flexDirection: "row" }}>
                                {/* {Array.from(6, (_, index) => ( */}
                                {[1, 2, 3, 4, 5, 6].map((_, index) => {
                                    return (<div className="inputbox"
                                        style={{ marginRight: index === 5 ? 0 : 10 }}
                                        key={index}
                                    >
                                        <input
                                            ref={inputRefs[index]}
                                            style={{ textAlign: 'center' }}
                                            className="border border-[#c2c8d0] p-3 focus:outline-none w-full"
                                            type="number"
                                            maxLength={1}
                                            required
                                            onChange={(e) => handleInput(index, e)}
                                            value={otp[index]}
                                        />
                                    </div>)
                                })}

                            </div>
                            }

                            <div style={{ textAlign: "left", color: "red", fontSize: 15 }}>{errorMsg}</div>
                            {listenOtp && <div style={{ textAlign: "center", color: "black", fontSize: 15 }}>{seconds > 0 ? ('Resend OTP in ' + seconds) : (<span onClick={register} style={{ textAlign: "left", color: "black", fontSize: 15, textDecoration: 'underline', cursor: 'pointer' }}>Resend OTP</span>)}</div>}


                            {listenOtp ? <button
                                onClick={login}
                                className="bg-[#10a37f] text-white p-3 rounded-sm"
                            >
                                Log in
                            </button> : <button
                                onClick={register}
                                className="bg-[#10a37f] text-white p-3 rounded-sm"
                            >
                                Send OTP
                            </button>}


                            {/* <button onClick={() => navigate('/login')} title="Go to Login Page" className="text-black text-center">Already have an account? Login</button> */}
                            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
                                <span className="text-black text-center">Don't have an account? </span><button onClick={() => navigate('/register')} title="Go to Registration Page" className="text-center" style={{ marginLeft: 10, color: '#10a37f' }}>Sign Up</button>
                            </div>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px", maxWidth: 345 }}>
                            <span className="text-center" style={{ color: "black", fontSize: 15 }}>
                                By logging into Appstorm, you are agreeing to our <span onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</span> and <span onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</span>
                            </span>
                        </div> */}
                        <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px" }}>
                            <div onClick={() => window.open('https://www.appstorm.ai/terms-of-service', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Terms of use</div>
                            <div style={{ textAlign: "left", color: "black", fontSize: 15, margin: "0px 10px" }}>|</div>
                            <div onClick={() => window.open('https://www.appstorm.ai/privacy-policy', '_blank')} style={{ textAlign: "left", color: "#10a37f", fontSize: 15, cursor: 'pointer' }}>Privacy Policy</div>
                        </div>
                    </section>
            }
        </main>
    );
};

export default Mobile;
